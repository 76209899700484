<template>
  <div class="box">
    <el-dialog
      title="系统开账"
      :visible.sync="info.show"
      width="40%"
      @closed="reset"
    >
      <div
        style="border: 1px solid #ccc; padding: 20px 20px; margin-bottom: 15px"
      >
        <div style="color: red">注意:</div>
        <ul
          style="
            list-style-type: decimal;
            line-height: 25px;
            color: #000;
            margin-top: 10px;
            margin-left: 20px;
          "
        >
          <li>系统开帐时，必须选择开帐月份，开帐月份必须在本年内</li>
          <li>开帐后，无法设置各项期初</li>
          <li>开帐后，可以录入业务单据</li>
          <li>开帐后，不允许补录开帐月份前的单据</li>
          <li>开帐后，每个月份结束时，系统自动进行月结存，核算成本并锁定</li>
        </ul>
      </div>
      <el-form :model="searcForm" class="F">
        <div class="month">
          <el-form-item label="月份:" style="width: 330px">
            <el-date-picker
              v-model="searcForm.date"
              :picker-options="pickerOption"
              type="month"
              placeholder="选择月"
              value-format="yyyy-MM"
            ></el-date-picker>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reset">取 消</el-button>
        <el-button type="primary" @click="updConfigBill">开 账</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updConfigBill } from "@/api/user";
import { mapState, mapMutations } from "vuex";
import { getDate } from "@/until/auth";
export default {
  props: {
    info: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      searcForm: {
        date: "",
      },
      pickerOption: {
        disabledDate(time) {
          let now =  new Date();
          let flag = false;
          if(now.getFullYear() != time.getFullYear() ||  time.getTime() > Date.now() - 8.64e7 ){
            flag = true;
          }
          return flag;
        },
      },
    };
  },
  computed: {
    ...mapState("common", [
      "menuActiveName",
      "mainTabs",
      "mainTabsActiveName",
      "ActiveId",
    ]),
  },
  created() {
    this.getDate();
  },
  mounted() {
    if (sessionStorage.getItem("cacheData") !== null) {
      this.$router.push("/" + sessionStorage.getItem("cacheData"));
      this.ActiveName = sessionStorage.getItem("cacheData");
      this.updateMainTabsActiveName(sessionStorage.getItem("cacheData"));
    }
  },
  methods: {
    ...mapMutations("common", [
      "updateMainTabsActiveName",
      "updateMainTabs",
      "updateMenuActiveName",
      "updataActiveId",
    ]),
    reset() {
      this.info.show = false;
      this.removeTabHandle(this.mainTabsActiveName);
    },
    removeTabHandle(tabName) {
      let tablist = this.mainTabs;
      console.log(tablist);
      tablist = this.mainTabs.filter((item) => item.urlName !== tabName);
      this.updateMainTabs(tablist);
      if (this.mainTabs.length >= 1) {
        // 当前选中tab被删除
        if (tabName === this.mainTabsActiveName) {
          var tab = this.mainTabs[this.mainTabs.length - 1];
          this.$router.push(
            { path: "/" + tab.urlName, query: tab.query, params: tab.params },
            () => {
              this.updateMainTabsActiveName(this.$route.name);
            }
          );
        }
      } else {
        this.updateMainTabsActiveName("");
        this.$router.push({ path: "/home" });
      }
    },
    // 默认时间
    getDate() {
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month =
        now.getMonth() + 1 >= 10
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      //   var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
      var defaultDate = `${year}-${month}`;
      //   this.searcForm.startDate = `${year}-${month}-01`;
      //   this.searcForm.endDate = `${year}-${month}-${date}`;
      this.searcForm.date = defaultDate;
    },
    async updConfigBill() {
      let params = {
        billDate: this.searcForm.date,
      };
      let res = await updConfigBill(params);
      if (res.code == 1) {
        this.info.show = false;
        this.$message({
          type: success,
          message: res.msg,
        });
      }
    },
  },
};
</script>

<style scoped>
.box >>> .el-dialog__body {
  padding: 30px 70px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.month >>> .el-picker-panel .el-picker-panel__icon-btn {
  font-size: 12px;
  color: #fff;
  border: 0;
  background: 0 0;
  cursor: pointer;
  outline: 0;
  margin-top: 8px;
  display: none !important;
}
</style>