import request from '@/until/request.js'
const prefixName = '/gm/webPrdInfo'

// 获取商品类别列表
export function GetProductTypeList(data){
  return request({
    url: '/gm/webPrdType',
    method: 'get',
    params: { ...data }
  })
}
// 创建商品类别
export function AddProductType(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 修改供应商
export function EditProductType(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 删除供应商
export function DeleteProductType(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}