<template>
  <div style="position: relative">
    <el-table
      :data="tableList"
      border
      height="58vh"
      size="mini"
      show-summary
      :summary-method="getSummaries"
      style="width: 100%"
      @cell-click="tabClick"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName"
    >
      <el-table-column align="center" label="序号" width="50">
        <template scope="scope">
          <span>{{ scope.$index + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        v-for="(item, index) in tableColumnList"
        :class-name="`${item.prop}_label ${
          item.edit ? 'editColumn' : 'forbidColumn'
        }`"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :width="item.width ? item.width : '160px'"
      >
        <template slot="header" slot-scope="scope">
          <span v-if="item.required" style="color: red; margin-right: 5px"
            >*</span
          ><span>{{ item.label }}</span>
        </template>
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRow &&
              scope.column.index === clickCell &&
              item.edit
            "
          >
            <el-row type="flex">
              <el-col :span="18"
                ><el-input
                  v-if="item.type === 'input' && item.prop === 'batchNo'"
                  ref="editInput"
                  v-model="scope.row[item.prop]"
                  size="mini"
                  style="width: 100%"
                  onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                  @blur="inputBlur($event, scope.row)"
              /></el-col>
              <el-col :span="6"
                ><div
                  v-if="item.prop === 'batchNo'"
                  class="buttonSeach"
                  @click="SelectBatchNo(scope.row)"
                >
                  <i class="el-icon-zoom-in"></i></div
              ></el-col>
            </el-row>
            <el-input
              v-if="item.type === 'input' && item.prop !== 'batchNo'"
              ref="editInput"
              v-model="scope.row[item.prop]"
              size="mini"
              onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
              @blur="inputBlur($event, scope.row)"
            />
            <el-select
              v-if="item.type === 'select'"
              v-model="scope.row[item.prop]"
              placeholder="请选择"
              size="mini"
              @change="
                (val) => {
                  ChangeSelect(val, scope.row);
                }
              "
            >
              <el-option
                v-for="(item, index) in scope.row.unitList"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-row type="flex">
              <el-col :span="item.type === 'number' ? 24 : 20"
                ><el-input-number
                  v-if="item.type === 'number' || item.type === 'price'"
                  v-model="scope.row[item.prop]"
                  controls-position="right"
                  :min="item.min"
                  :max="
                    item.prop === 'quantity' ? scope.row.inventory : item.max
                  "
                  size="mini"
                  @blur="inputBlur"
                  @change="inputChange($event, scope.row)"
                  :precision="item.precision"
                ></el-input-number
              ></el-col>
              <el-col :span="4" v-if="item.type === 'price'"
                ><div class="buttonSeach" @click="selectPrice(scope.row)">
                  <i class="el-icon-zoom-in"></i></div
              ></el-col>
            </el-row>
            <el-date-picker
              v-if="item.type === 'date'"
              v-model="scope.row[item.prop]"
              type="date"
              size="mini"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期"
              @change="() => changeDate(scope.row)"
            >
            </el-date-picker>
          </div>
          <div
            v-else
            :style="
              item.type === 'price'
                ? { 'text-align': 'right' }
                : { 'text-align': 'left' }
            "
          >
            <span v-if="item.type === 'price'">{{
              scope.row[item.prop] | backPrice
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="200" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            :disabled="examine || tableList.length > 50"
            @click="addData(scope.$index)"
            >插入</el-button
          >
          <el-button
            type="text"
            :disabled="examine || tableList.length <= 1"
            @click="deleteData(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 退货单 -->
    <div>
      <!-- 是否为查看状态 -->
      <div v-if="!examine" style="width: 100%; margin-top: 20px">
        <el-row> 制单人：{{ name }} </el-row>
        <el-row style="margin-top: 20px">
          <el-form size="mini" :inline="true" :model="pustorageForm">
            <el-form-item label="优惠金额：">
              <el-input-number
                size="mini"
                v-model="pustorageForm.preferentialPrice"
                controls-position="right"
                :min="0.0"
                :max="allPrice"
                :precision="2"
                @change="ChangePrice"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="本次应退金额：">
              <span v-if="pustorageForm.handlePrice">
                {{ pustorageForm.handlePrice | backPrice }}
              </span>
              <span v-else>0.00</span>
            </el-form-item>
            <el-form-item label="本次退款金额：">
              <el-input-number
                size="mini"
                v-model="pustorageForm.thisPayment"
                controls-position="right"
                :min="0.0"
                :max="pustorageForm.handlePrice"
                @change="ChangeArrearage"
                :precision="2"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="退款账户：">
              <el-select
                v-model="pustorageForm.accountId"
                placeholder="请选择"
                clearable
                filterable
                :disabled="
                  pustorageForm.thisPayment === 0 ||
                  pustorageForm.thisPayment === undefined
                "
                @clear="clearAccountId"
              >
                <el-option
                  v-for="item in AccountList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="本次欠款金额：">
              <span v-if="arrearage">
                {{ arrearage | backPrice }}
              </span>
              <span v-else>0.00</span>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <!-- 查看状态 -->
      <div v-else style="width: 100%; margin-top: 20px">
        <el-row type="flex" justify="start">
          <el-col :span="3"> 制单人：{{ examineForm.createUserName }} </el-col>
          <el-col :span="6">
            最后修改时间：{{ examineForm.updateTime }}
          </el-col>
          <el-col :span="4">
            单据状态：{{ examineForm.status == "0" ? "已完成" : "草稿" }}
          </el-col>
        </el-row>
        <el-row style="margin-top: 20px">
          <el-form size="mini" :inline="true" :model="examineForm">
            <el-form-item label="优惠金额：">
              <el-input-number
                size="mini"
                v-model="examineForm.preferentialPrice"
                controls-position="right"
                :disabled="true"
                :precision="2"
                @change="handlePriceCount"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="本次应退金额：">
              <span v-if="examineForm.handlePrice">
                {{ examineForm.handlePrice | backPrice }}
              </span>
              <span v-else>0.00</span>
            </el-form-item>
            <el-form-item label="本次退款金额：">
              <el-input-number
                size="mini"
                :disabled="true"
                v-model="examineForm.prepaidPrice"
                controls-position="right"
                :precision="2"
                @change="ChangeArrearage"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="退款账户：">
              <span style="margin-right: 20px">{{
                examineForm.accountName ? examineForm.accountName : "-"
              }}</span>
            </el-form-item>
            <el-form-item label="本次欠款金额：">
              <span v-if="examineForm.handlePrice - examineForm.prepaidPrice">
                {{
                  (examineForm.handlePrice - examineForm.prepaidPrice)
                    | backPrice
                }}
              </span>
              <span v-else>0.00</span>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
    </div>
    <!-- 选择商品 -->
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods
          :isPutStorage="false"
          :miaWmsId="wmsId"
          @close="Visible = false"
          @SelectGoods="AddGoods"
        />
      </div>
    </miaDialog>
    <!-- 选择批次商品 -->
    <miaDialog width="70%" ref="SelectBatchGoods" v-if="BatchGoodsVisible">
      <div slot="content">
        <SelectBatchGoods
          :data="currentBatchData"
          :miaWmsId="wmsId"
          @close="BatchGoodsVisible = false"
          @SelectBatchGoods="AddBatchGoods"
        />
      </div>
    </miaDialog>
    <!-- 选择序列码商品 -->
    <miaDialog width="70%" ref="SelectSerialGoods" v-if="SerialGoodsVisible">
      <div slot="content">
        <SelectSerialGoods
          :data="currentBatchData"
          :isPutStorage="false"
          :miaWmsId="wmsId"
          @close="SerialGoodsVisible = false"
          @SelectSerialGoods="AddSerialGoods"
        />
      </div>
    </miaDialog>
    <!-- 选择单价 -->
    <miaDialog width="70%" ref="SelectPrice" v-if="SelectPriceVisible">
      <div slot="content">
        <SelectPrice
          :data="PriceData"
          @close="SelectPriceVisible = false"
          @SelectPrice="PriceChange"
        />
      </div>
    </miaDialog>
    <!-- 查看序列码 -->
    <miaDialog width="70%" ref="examineSerialGoods" v-if="examineSerialGoods">
      <div slot="content">
        <el-table
          :data="examineSerialGoodsList"
          highlight-current-row
          border
          height="55vh"
        >
          <el-table-column prop="code" label="序列码"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
      </div>
    </miaDialog>
    <div
      class="mask"
      v-if="!wmsId"
      @click="$message.error('请选择出库仓库')"
    ></div>
  </div>
</template>
<script>
import SelectGoods from "../pustorageTable/SelectGoods.vue";
import SelectBatchGoods from "../pustorageTable/selectBatchGoods.vue";
import SelectSerialGoods from "../pustorageTable/selectSerialGoods.vue";
import SelectPrice from "../pustorageTable/selectPrice.vue";
import {
  SalesreturnGoods,
  SalesreturnTableGoods,
  SalesreturnBatchGoods,
  SalesreturnSerialGoods,
} from "@/until/statement.js";
import { GetAccountList } from "@/api/account";
import { mapState } from "vuex";
import { setInterval } from "../../until/auth";
export default {
  name: "SalesreturnTable",
  components: {
    SelectGoods,
    SelectBatchGoods,
    SelectSerialGoods,
    SelectPrice,
  },
  computed: {
    ...mapState("user", ["name"]),
  },
  props: {
    wmsId: {
      default: () => "",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    examine: {
      type: Boolean,
      default: () => false,
    },
    examineForm: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
    editForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clickRow: null, // 当前点击的行
      clickCell: null, // 当前点击的列
      tabClickLabel: "", // 当前点击的列名
      mask: true,
      // 当前展示的列表
      tableList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pustorageForm: {
        purchasePrice: 0,
        preferentialPrice: 0,
        handlePrice: 0,
        thisPayment: 0,
        accountId: "",
      },
      // 欠款金额
      arrearage: 0,
      Visible: false,
      currentPointIndex: null,
      AccountList: [],
      BatchGoodsVisible: false,
      currentBatchData: null,
      SerialGoodsVisible: false,
      SelectPriceVisible: false,
      examineSerialGoods: false,
      examineSerialGoodsList: [],
      PriceData: {},
      allPrice: null,
      // 编辑表格
      tableColumnList: [
        {
          prop: "producName",
          label: "商品名称",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "code",
          label: "商品编号",
          type: "input",
          edit: false,
          required: false,
        },
        {
          prop: "producTypeName",
          label: "管理类型",
          type: "input",
          edit: false,
          required: false,
        },
        // {
        //   prop: "batchNo",
        //   label: "批次号",
        //   type: "input",
        //   edit: true,
        //   required: true,
        // },
        // {
        //   prop: "productionDate",
        //   label: "生产日期",
        //   type: "date",
        //   edit: true,
        //   width: "100px",
        //   required: true,
        // },
        // {
        //   prop: "expire",
        //   label: "保质期天数",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        // {
        //   prop: "dateDue",
        //   label: "到期日期",
        //   type: "date",
        //   edit: false,
        //   width: "100px",
        //   required: false,
        // },
        {
          prop: "unit",
          label: "单位",
          type: "select",
          width: "100px",
          edit: true,
          required: true,
        },
        {
          prop: "unitConversion",
          label: "单位换算",
          type: "input",
          width: "100px",
          edit: false,
          required: false,
        },
        {
          prop: "inventory",
          label: "当前库存",
          type: "input",
          width: "80px",
          edit: false,
          required: false,
        },
        {
          prop: "backNumber",
          label: "可退数量",
          type: "input",
          width: "80px",
          edit: false,
          required: false,
        },
        {
          prop: "quantity",
          label: "数量",
          type: "number",
          edit: true,
          min: 1,
          max: 9999,
          precision: 0,
          width: "100px",
          required: true,
        },
        {
          prop: "price",
          label: "单价",
          type: "price",
          edit: true,
          min: 0.0,
          max: 99999.99,
          precision: 2,
          width: "150px",
          required: true,
        },
        {
          prop: "money",
          label: "金额",
          type: "price",
          width: "100px",
          edit: false,
          required: false,
        },
      ],
    };
  },
  watch: {
    tableData(val) {
      this.tableList = val;
    },
    tableList(val) {
      this.handlePriceCount();
    },
    wmsId(val) {
      this.wmsId = val;
    },
    examine(val) {
      this.examine = val;
    },
    examineForm(val) {
      this.examineForm = val;
      this.arrearage = val.handlePrice - val.prepaidPrice;
    },
    editForm(val) {
      this.editForm = val;
      if (this.isEdit && this.editForm) {
        // 修改的时候把金额赋值
        Object.keys(this.pustorageForm).forEach((key) => {
          if (this.editForm[key] && this.editForm[key] !== undefined) {
            this.pustorageForm[key] = this.editForm[key];
          }
        });
        this.pustorageForm.thisPayment = val.prepaidPrice;
        this.arrearage = val.handlePrice - val.prepaidPrice;
      }
    },
  },
  filters: {
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  created() {
    this.tableList = JSON.parse(JSON.stringify(this.tableData));
    this.GetAccount();
  },
  methods: {
    // 控制input显示 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      if (!this.examine) {
        if (this.wmsId) {
          this.currentPointIndex = row.index;
          if (column.property === "producName") {
            this.Visible = true;
            this.$nextTick(() => {
              this.$refs.SelectGoods.init("选择商品");
            });
          } else {
            if (row.producName) {
              // 判断是否为批次商品
              if (!row.isBatch && !row.isSerial) {
                // 普通商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                )
                  return;
              }
              if (row.isSerial && !row.isBatch) {
                // 序列商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                )
                  return;
                if (column.property === "quantity") {
                  this.SerialGoodsVisible = true;
                  this.currentBatchData =
                    this.tableList[this.currentPointIndex];
                  this.$nextTick(() => {
                    this.$refs.SelectSerialGoods.init("序列码录入");
                  });
                  return;
                }
              }
              if (row.isBatch && !row.isSerial) {
                // 批次商品
                if (column.property === "batchNo") {
                  this.SelectBatchNo(row);
                  return;
                }
                if (column.property === "productionDate") {
                  if (row.batchNo) {
                    return;
                  }
                }
              }
              if (row.isBatch && row.isSerial) {
                // 批次序列码商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                )
                  return;
                if (column.property === "quantity") {
                  this.SerialGoodsVisible = true;
                  this.currentBatchData =
                    this.tableList[this.currentPointIndex];
                  this.$nextTick(() => {
                    this.$refs.SelectSerialGoods.init("序列码录入");
                  });
                  return;
                }
              }
              this.openEdit(row.index, column.index, column.label);
            } else {
              return;
            }
          }
        } else {
          this.$message.error("请先选择您要出库的仓库");
        }
      } else {
        if (row.serialNoList && column.property === "quantity") {
          this.examineSerialGoods = true;
          this.examineSerialGoodsList = [];
          row.serialNoList.forEach((val) => {
            this.examineSerialGoodsList.push({ code: val, remark: "" });
          });
          this.$nextTick(() => {
            this.$refs.examineSerialGoods.init("查看序列码列表");
          });
        }
      }
    },
    openEdit(rowIndex, columnIndex, label) {
      this.clickRow = rowIndex;
      this.clickCell = columnIndex;
      this.tabClickLabel = label;
    },
    // 把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.isBatch) {
        return "BatchGoods";
      }
      if (row.isSerial) {
        return "SerialGoods";
      }
      if (row.isBatch === 0 && row.isSerial === 0) {
        return "GeneralGoods";
      }
      return "";
    },
    // 把每一列的索引放进column
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    // 失去焦点初始化
    inputBlur(row, data) {
      console.log(data.batchNo);
      if (data.batchNo) {
        if (data.batchNo.length > 32 || data.batchNo.length < 6) {
          data.batchNo = "";
          this.$message.error("批次号的长度在6-32位之间");
        } else {
          this.openEdit(null, null, null);
        }
      } else {
        this.openEdit(null, null, null);
      }
    },
    // 添加选中的商品至表格
    AddGoods(data) {
      debugger
      this.openEdit(null, null, null);
      this.Visible = false;
      if (data.length !== 0) {
        let list = [];
        data.forEach((v, index) => {
          // 构建普通商品
          let obj = null;
          // if (!v.isBatch && !v.isSerial) {
          obj = {
            ...new SalesreturnGoods(
              v.id,
              v.name,
              v.id,
              v.code,
              "普通",
              v.unitList[0].name,
              v.unitList[0].id,
              // v.unitList,
              "-",
              v.num,
              null,
              1,
              v.unitList[0].purchasePrice,
              null
            ),
          };
          // }
          // 构建(单)批次商品
          if (v.isBatch && !v.isSerial) {
            obj = {
              ...new SalesreturnBatchGoods(
                v.id,
                v.name,
                v.id,
                v.code,
                "批次",
                null,
                null,
                v.expire,
                null,
                v.unitList[0].name,
                v.unitList[0].id,
                v.unitList,
                "-",
                null,
                null,
                1,
                v.unitList[0].purchasePrice,
                null
              ),
            };
          }
          if (!v.isBatch && v.isSerial) {
            // 构建(单)序列码商品
            obj = {
              ...new SalesreturnSerialGoods(
                v.id,
                v.name,
                v.id,
                v.code,
                "序列码",
                v.unitList[0].name,
                v.unitList[0].id,
                v.unitList,
                "-",
                v.num,
                null,
                [],
                0,
                v.unitList[0].purchasePrice,
                null
              ),
            };
          }
          if (v.isBatch && v.isSerial) {
            // 批次序列码商品
            obj = {
              ...new SalesreturnBatchGoods(
                v.id,
                v.name,
                v.id,
                v.code,
                "批次序列码",
                null,
                null,
                v.expire,
                null,
                v.unitList[0].name,
                v.unitList[0].id,
                v.unitList,
                "-",
                v.num,
                null,
                1,
                v.unitList[0].purchasePrice,
                null
              ),
            };
            obj.serialNoList = [];
            obj.quantity = 0;
            obj.isBatch = 1;
            obj.isSerial = 1;
          }
          obj.money = obj.quantity * obj.price;
          list.push(obj);
        });
        // this.tableData.splice(this.currentPointIndex, 1, ...list);
        this.tableData = list;
        console.log(this.tableData);
      }
    },
    // 选择生产日期
    changeDate(data) {
      if (data.productionDate) {
        data.dateDue = this.getNewDay(data.productionDate, data.expire);
      } else {
        data.dateDue = "";
      }
    },
    // 计算到期日期
    getNewDay(dateTemp, days) {
      if (!dateTemp) return;
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
    // 单位选择（更新单位换算列及相关价格）
    ChangeSelect(value, data) {
      const item = data.unitList.filter((val) => {
        return val.name == value;
      });
      if (item[0].isInit === 1) {
        // 为辅助单位
        const arr = data.unitList.filter((val) => {
          return val.isInit === 0;
        })[0];
        data.unitConversion = `${arr.value}${item[0].name} = ${item[0].value}${arr.name}`;
      } else {
        data.unitConversion = "-";
      }
      data.unitId = item[0].id;
      data.price = Number(item[0].purchasePrice);
      data.money = Number(data.quantity) * Number(item[0].purchasePrice);
      this.handlePriceCount();
    },
    inputChange(value, data) {
      data.money = Number(data.quantity) * Number(data.price);
      this.handlePriceCount();
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //calcProp 为需要计算的列的prop值的集合
        if (column.property === "quantity") {
          const values = data.map((item) => item["quantity"]);
          sums[index] = values.reduce((prev, curr) => {
            if (curr) {
              return prev + curr;
            } else {
              return prev;
            }
          });
        }
        if (column.property === "price" || column.property === "money") {
          const values = data.map((item) => Number(item[column.property]));
          let num = 0;
          values.forEach((v) => {
            if (v) {
              return (num += parseFloat(v));
            } else {
              return (num += 0);
            }
          });
          sums[index] = this.backPrice((num.toFixed(3) * 1000) / 1000);
        }
      });
      return sums;
    },
    // 获取账户信息
    GetAccount() {
      GetAccountList({}).then((res) => {
        if (res.code === 1) {
          this.AccountList = res.data.records;
        } else {
          this.AccountList = [];
        }
      });
    },
    // 计算合计金额
    handlePriceCount() {
      let num = 0;
      this.tableList.forEach((v) => {
        if (v.money) {
          num += parseFloat(v.money);
        } else {
          num += 0;
        }
      });
      num = (num.toFixed(3) * 1000) / 1000;
      this.pustorageForm.purchasePrice = num;
      this.allPrice = num;
      this.pustorageForm.handlePrice =
        Number(this.pustorageForm.purchasePrice) -
        Number(this.pustorageForm.preferentialPrice);
      // 改变应退金额
      this.ChangePrice(this.pustorageForm.preferentialPrice);
      // 欠款金额
      this.ChangeArrearage(this.pustorageForm.thisPayment);
    },
    // 改变优惠金额
    ChangePrice(value) {
      if (value === undefined) return;
      // 改变优惠金额的时候改变应退金额
      this.pustorageForm.handlePrice =
        Number(this.pustorageForm.purchasePrice) - Number(value);
      // 大于的时候
      if (this.pustorageForm.thisPayment > this.pustorageForm.handlePrice) {
        this.pustorageForm.thisPayment = this.pustorageForm.handlePrice;
      }
      // 欠款金额
      this.ChangeArrearage(this.pustorageForm.thisPayment);
    },
    // 计算欠款金额
    ChangeArrearage(value) {
      if (value === undefined) {
        this.pustorageForm.accountId = "";
        this.arrearage = Number(this.pustorageForm.handlePrice);
        return;
      }
      if (this.pustorageForm.thisPayment === 0)
        this.pustorageForm.accountId = "";
      // 欠款金额 =  应退金额 - 本次退款金额
      this.arrearage =
        Number(this.pustorageForm.handlePrice) -
        Number(this.pustorageForm.thisPayment);
    },
    // 插入行
    addData(index) {
      if (this.tableData.length >= 50)
        return this.$message.error("最多只能插入50行");
      const item = {
        ...new SalesreturnTableGoods(),
      };
      this.tableData.splice(index + 1, 0, item);
    },
    // 删除行
    deleteData(index) {
      if (this.tableData.length <= 1) {
        this.$message.error("至少保留1行数据");
        return;
      }
      this.tableData.splice(index, 1);
    },
    // 打开选择批次商品的弹框
    SelectBatchNo(data) {
      this.currentBatchData = data;
      this.BatchGoodsVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectBatchGoods.init("选择批次商品");
      });
    },
    // 添加批次商品
    AddBatchGoods(list, data) {
      if (list.length === 0) return this.$message.error("请选择需要出库的批次");
      this.BatchGoodsVisible = false;
      // list 选择添加的批次号数据
      // data 当前的批次商品信息 如果list位多个 将重复添加商品及批次号
      if (list.length === 1) {
        // 一个数据
        this.tableList[data.index].batchNo = list[0].batchNo;
        this.tableList[data.index].dateDue = list[0].dateDue;
        this.tableList[data.index].expire = list[0].expire;
        this.tableList[data.index].productionDate = list[0].manufactureDate;
        this.tableList[data.index].quantity = 1;
        this.tableList[data.index].inventory = list[0].num;
      } else {
        const item = this.tableList[this.currentPointIndex];
        let pointTabel = [];
        list.forEach((val) => {
          const obj = { ...item };
          Object.keys(val).forEach((key) => {
            if (val[key]) {
              obj[key] = val[key];
            }
          });
          obj.productionDate = val.manufactureDate;
          obj.inventory = val.num;
          obj.quantity = 1;
          pointTabel.push(obj);
        });
        this.tableData.splice(this.currentPointIndex, 1, ...pointTabel);
      }
      this.openEdit(null, null, null);
    },
    // 添加序列码商品
    AddSerialGoods(list, status) {
      const item = this.tableList[this.currentPointIndex];
      item.serialNoList = list;
      item.quantity = list.length;
      item.money = Number(item.quantity) * Number(item.price);
      this.handlePriceCount();
      this.SerialGoodsVisible = false;
      this.currentPointIndex = null;
      this.currentBatchData = null;
      if (!status) {
        // 退货
        if (list.length == 1) {
          item.batchNo = list[0].batchNo;
          item.productionDate = list[0].productionDate;
          item.dateDue = this.getNewDay(item.productionDate, item.expire);
          item.serialNoList = list.map((val) => {
            return val.code;
          });
        } else {
          // 根据序列码的批次号不同创建商品
          const result = this.removeRepeat(list);
          if (result.length === 1) {
            //  序列码只有一个批次号
            item.batchNo = result[0].batchNo;
            item.productionDate = result[0].productionDate;
            item.quantity = result[0].num;
            item.dateDue = this.getNewDay(item.productionDate, item.expire);
            item.serialNoList = result[0].serialNoList;
            item.money = item.quantity * item.price;
          } else if (result.length > 1 && result.length !== 0) {
            //   序列码中有多个批次号
            let list = [];
            result.forEach((val) => {
              let obj = { ...item };
              obj.batchNo = val.batchNo;
              obj.productionDate = val.productionDate;
              obj.quantity = val.num;
              obj.dateDue = this.getNewDay(obj.productionDate, obj.expire);
              obj.serialNoList = val.serialNoList;
              obj.money = obj.quantity * obj.price;
              list.push(obj);
            });
            this.tableData.splice(this.currentPointIndex, 1, ...list);
            
          }
        }
      }
    },
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    selectPrice(data) {
      this.PriceData = data;
      this.SelectPriceVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectPrice.init("选择单价");
      });
    },
    // 更改单价
    PriceChange(val) {
      const item = this.tableList[this.currentPointIndex];
      item.price = val.price;
      this.inputChange(null, item);
      this.SelectPriceVisible = false;
    },
    clearAccountId() {
      this.pustorageForm.thisPayment = 0;
      this.handlePriceCount();
    },
    // 序列码查重
    removeRepeat(list) {
      let oldArr = JSON.parse(JSON.stringify(list)).map((val) => {
        val.num = 0;
        val.serialNoList = [];
        return val;
      });
      let newArr = [];
      newArr[0] = oldArr[0];
      for (var i = 0; i < oldArr.length; i++) {
        //新数组
        for (var j = 0; j < newArr.length; j++) {
          //原数组和新数组元素值相同结束内循环
          if (
            oldArr[i].batchNo == newArr[j].batchNo &&
            oldArr[i].productionDate === newArr[j].productionDate
          ) {
            newArr[j].num += 1;
            newArr[j].serialNoList.push(oldArr[i].code);
            break;
          }
          //原数组和新数组最后一个元素不同，插入新数组
          if (j == newArr.length - 1) {
            newArr.push(oldArr[i]);
          }
        }
      }
      return newArr;
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonSeach {
  width: 20px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  cursor: pointer;
}
// 普通商品
/deep/ .GeneralGoods {
  .batchNo_label,
  .productionDate_label,
  .expire_label,
  .dateDue_label {
    background-color: #f5f7fa !important;
  }
}
/deep/ .el-table__footer-wrapper {
  .el-table__footer {
    td.el-table__cell {
      div {
        text-align: right;
        // 其他⾃定义样式
      }
    }
  }
}
</style>