import request from '@/until/request.js'
const prefixName = '/cw/webAut'

// 获取账户列表
export function GetAccountList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
export function AddAccount(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
export function EditAccount(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
export function DeleteAccount(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
export function SettingInitial(result){
  return request({
    url: '/yny/webInitial/aut',
    method: 'post',
    data: result
  })
}