<template>
  <div>
    <el-table
      border
      style="margin-bottom: 20px"
      :data="data"
      :highlight-current-row="true"
      :header-cell-style="{
        background: '#F5F6F8',
        color: '#333333',
        'text-align': 'center',
      }"
      @selection-change="handleSelectionChange"
    >
      <template v-for="(item, index) in tableFields">
        <el-table-column
          v-if="item.type == 'default'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :width="item.width ? item.width : ''"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] ? scope.row[item.prop] : "-" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type == 'number'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'tag'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row[item.prop] ? item.showTextList[0] : item.showTextList[1]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'roleType'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row[item.prop] === "-1"
                ? item.showTextList[0]
                : item.showTextList[1]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'userType'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row[item.prop] === "1"
                ? item.showTextList[0]
                : item.showTextList[1]
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'refundNoslot'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot name="refundNoColumn" :data="scope.row" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'supplierslot'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot name="supplierColumn" :data="scope.row" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'payStatuslot'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot name="StatusColumn" :data="scope.row" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'statusSlot'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot name="Column" :data="scope.row" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'userRole'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.prop] | backName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'price'"
          :key="index"
          :show-overflow-tooltip="true"
          :prop="item.prop"
          align="right"
          :label="item.label"
        >
          <template slot-scope="scope">
            <span v-if="scope.row[item.prop] === undefined"> - </span>
            <span v-else>
              <span v-if="scope.row[item.prop]">
                {{ scope.row[item.prop] | backPrice }}
              </span>
              <span v-else>{{ "0.00" }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'operation'"
          :key="index"
          prop="operation"
          :label="item.label"
        >
          <template slot-scope="scope">
            <slot name="operation" :data="scope.row" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.type === 'selection'"
          :key="index"
          type="selection"
          align="center"
          width="55"
        >
        </el-table-column>
      </template>
    </el-table>
    <el-pagination
      v-if="showPagination"
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "SimpleTable",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tableFields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    operation: {
      type: Array,
      default: () => {
        return [];
      },
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
  },
  filters: {
    backName(val) {
      let name = "";
      const list = val.map((item) => {
        return item.name;
      });
      name = list.join(",");
      return name;
    },
    backPrice(val) {
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("sizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("currentChange", val);
    },
    handleSelectionChange(val) {
      this.$emit("selectChange", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  cursor: pointer;
  i {
    font-size: 18px;
    margin-right: 41px;
  }
}
.button--edit:hover {
  color: #409eff;
}
.button--delete:hover {
  color: #f56c6c;
}
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
</style>
<style lang="scss">
/* 鼠标移入*/
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background: #f3f5fc !important;
  color: #333;
}

/* 鼠标选中*/
.el-table__body tr.current-row > td {
  background: #f3f5fc !important;
  color: #333;
  .button--edit {
    color: #409eff;
  }
  .button--delete {
    color: #f56c6c;
  }
}
</style>
