<template>
  <div>
    <el-row
      ><span>商品：{{ data.producName }}</span
      ><span style="margin-left: 20px"
        >仓库：{{ miaWmsId | backName(WmsList) }}</span
      ></el-row
    >
    <el-row style="margin-top: 20px">
      <el-table
        :data="serialNoList"
        highlight-current-row
        border
        size="mini"
        height="55vh"
        @cell-click="serialNoClick"
        :row-class-name="serialNoRowClassName"
        :cell-class-name="serialNollClassName"
      >
        <el-table-column align="center" label="序号" width="50">
          <template scope="scope">
            <span>{{ scope.$index + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-for="(item, index) in serialColumn"
          :class-name="item.edit ? 'editColumn' : 'forbidColumn'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.required" style="color: red; margin-right: 5px"
              >*</span
            ><span>{{ item.label }}</span>
          </template>
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.index === serialNoRow &&
                scope.column.index === serialNoCell &&
                item.edit
              "
            >
              <el-input
                v-if="item.type === 'input'"
                ref="editInput"
                v-model="scope.row[item.prop]"
                size="mini"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                @change="(val) => handleChange(val, scope)"
              />
            </div>
            <div v-else style="text-align: right">
              <span v-if="item.prop === 'remark'" style="color: red">{{
                scope.row[item.prop]
              }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="160px">
          <template slot-scope="scope">
            <el-button type="text" @click="addserialNoRow(scope.$index)"
              >插入</el-button
            >
            <el-button type="text" @click="deleteserialNoRow(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="width: 100%; text-align: center; margin-top: 30px">
      <el-button type="primary" @click="submitData">提交</el-button>
      <el-button @click="close">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { GetWmsList } from "@/api/warehouse";
import { search } from "@/api/expectInstall";
import { checkCode, getBatchByCode } from "@/api/purchaseRefund";
export default {
  props: {
    miaWmsId: {
      type: Number,
      default: () => 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
    isPutStorage: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      WmsList: [],
      serialNoList: [],
      serialNoRow: null,
      serialNoCell: null, // 当前点击的列
      serialNoClickLabel: "", // 当前点击的列名
      serialColumn: [
        {
          prop: "code",
          label: "序列码",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "remark",
          label: "备注",
          edit: false,
          required: false,
        },
      ],
    };
  },
  filters: {
    backName(val, list) {
      if (val) {
        const element = list.filter((item) => {
          return item.id === val;
        });
        if (element.length !== 0) {
          return element[0].name;
        } else {
          return "";
        }
      }
      return "";
    },
  },
  watch: {
    isPutStorage(val) {
      this.isPutStorage = val;
    },
  },
  created() {
    this.getWmsList();
    // 退货单 且为批次序列码商品为不同列表
    if(!this.isPutStorage && this.data.isBatch && this.data.isSerial) {
      this.serialColumn = [
        {
          prop: "code",
          label: "序列码",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "batchNo",
          label: "批次号",
          type: "input",
          edit: false,
          required: false,
        },
        {
          prop: "productionDate",
          label: "生产日期",
          type: "input",
          edit: false,
          required: false,
        },
        {
          prop: "remark",
          label: "备注",
          edit: false,
          required: false,
        },
      ]
    }
    const list = JSON.parse(JSON.stringify(this.data.serialNoList));
    if (list.length !== 0) {
      list.forEach((val) => {
        let obj = { code: val, remark: "" };
        // 退货单修改时查看批次
        if(!this.isPutStorage  && this.data.isBatch && this.data.isSerial) {
          obj.batchNo = this.data.batchNo
          obj.productionDate = this.data.productionDate
        }
        this.serialNoList.push(obj);
      });
      this.serialNoList.push({ code: "", remark: "" });
    } else {
      this.serialNoList = new Array(10)
        .fill({ code: "", remark: "" })
        .map((val) => {
          return { ...val };
        });
    }
  },
  methods: {
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsList = res.data.records;
        } else {
          this.WmsList = [];
        }
      });
    },
    // 控制input显示 row 当前行 column 当前列
    serialNoClick(row, column, cell, event) {
      var that = this;
      that.serialNoRow = row.index;
      that.serialNoCell = column.index;
      that.serialNoClickLabel = column.label;
    },
    // 把每一行的索引放进row
    serialNoRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    // 把每一列的索引放进column
    serialNollClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    indexMethod(index) {
      return index + 1;
    },
    handleChange(value, scope) {
      if (value) {
        if (value.length > 128) {
          this.$message.error(
            "序列码格式有误，请输入1-128位数字、字母、特殊字符"
          );
          value = null;
          return;
        } else {
          let data = { code: value };
          const repetitionIndex = this.findall(
            this.serialNoList
              .filter((item) => item.index !== scope.$index)
              .map((item) => item.code),
            value
          );
          if (repetitionIndex.length) {
            scope.row.remark = repetitionIndex.join("、");
          } else {
          const flag =  this.findother(
            this.serialNoList
              .filter((item) => item.index !== scope.$index && item.remark.indexOf('重复') !== -1),
            value
          );
          if(flag) {
          //   // 查询
            if (this.isPutStorage) {
              search(data).then((res) => {
                if (res.code === 1) {
                  scope.row.remark = "";
                  this.serialNoRow = null;
                  this.serialNoCell = null;
                  this.serialNoClickLabel = "";
                } else {
                  scope.row.remark = res.msg;
                  return
                }
              });
            } else {
              checkCode(
                value,
                this.miaWmsId,
                this.data.productId
              ).then((res) => {
                if (res.code === 1) {
                  scope.row.remark = '';
                  getBatchByCode(value,this.data.productId).then(res => {
                    if(res.code === 1) {
                      scope.row.batchNo = res.data.batchNo
                      scope.row.productionDate = res.data.manufactureDate
                      this.serialNoRow = null;
                  this.serialNoCell = null;
                  this.serialNoClickLabel = "";
                    } else {
                      return
                    }
                  })
                } else {
                  scope.row.remark = res.msg;
                }
              });
            }
          }
          }
        }
      } else {
        scope.row.remark = "";
        this.serialNoRow = null;
        this.serialNoCell = null;
        this.serialNoClickLabel = "";
      }
    },
    addserialNoRow(index) {
      if (this.serialNoList.length >= 100) {
        return this.$message.error("最多只能插入100行");
      }
      let element = new Object();
      element.code = null;
      element.remark = null;
      this.serialNoList.splice(index + 1, 0, element);
    },
    deleteserialNoRow(index) {
      if (this.serialNoList.length <= 1) {
        this.$message.error("至少保留1行数据");
        return;
      }
      this.serialNoList.splice(index, 1);
    },
    submitData() {
      const list = this.serialNoList.filter((val) => {
        return val.code;
      });
      let confirmList = []
      if (list.length === 0) {
        return this.$message.error("暂无有效数据可提交");
      } else {
        confirmList = list.filter((val) => {
          return val.remark !== "";
        });
        if (confirmList.length === 0) {
          // 没有重复数据
          const submitList = list.map((val) => {
            return val.code;
          });
          if(!this.isPutStorage) {
            this.$emit("SelectSerialGoods", list, false);
          } else {
            this.$emit("SelectSerialGoods", submitList);
          }
        } else {
          return this.$message.error("序列码录入错误，请查看备注");
        }
      }
    },
    close() {
      this.$emit("close");
    },
    findall(arr, code) {
      let result = [],
        len = arr.length,
        pos = 0;
      while (pos < len) {
        pos = arr.indexOf(code, pos);
        if (pos === -1) break;
        result.push(`与第${pos + 1}项重复`);
        pos += 1;
      }
      return result;
    },
    findother(arr,code) {
      if(arr.length === 0) return true
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        if(element.code !== code) {
            element.remark = ''
             return true
          } else {
            return false
          }
      }
    }
  },
};
</script>