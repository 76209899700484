import request from '@/until/request.js'
const prefixName = '/gm/webPrdInfo'

// 获取农药商品列表
export function GetProductList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 查询采购可入库商品列表
export function getProductParamList(data){
  return request({
    url: '/gm/webPrdInfo/getProductParamList',
    method: 'get',
    params: { ...data }
  })
}

// 多选商品获取详情
export function getDetailsList(data){
  return request({
    url: '/gm/webPrdInfo/detailsList',
    method: 'get',
    params: { ...data }
  })
}
// 获取商品编号
export function GetProductCode(data){
  return request({
    url: prefixName + '/getYnyPrdCode',
    method: 'get',
    params: { ...data }
  })
}
// 创建农药商品
export function AddProduct(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 获取预售价格模板
export function getYnyPriceTemplate(result){
  return request({
    url: '/gm/global/getTemplate',
    method: 'get',
    params: result
  })
}
// 通过扫码查询
export function GetProductInfo(code){
  return request({
    url: prefixName + '/' + code ,
    method: 'get'
  })
}
// 通过登记证号查询农药信息
export function searchProductInfo(code){
  return request({
    url: prefixName + '/byCode/' + code ,
    method: 'get'
  })
}
// 修改商品
export function EditProduct(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 获取农药商品详情
export function getDetails(result){
    return request({
        url: prefixName + '/details',
        method: 'get',
        params: result
    })
}
// 删除商品
export function DeleteProduct(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
// 修改状态
export function updateStatus(result){
  return request({
    url: prefixName + '/status',
    method: 'post',
    data: result
})
}
// 查询价格参照
export function getPriceReference(result){
  return request({
      url: prefixName + '/getPriceReference',
      method: 'get',
      params: result
  })
}