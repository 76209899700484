import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import user from './modules/user'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
  },
  getters: {
    token: state => state.user.token,
  },
  mutations: {
    // 重置vuex本地储存状态
    resetStore (state) {
      Object.keys(state).forEach((key) => {
        Object.keys(state[key]).forEach((childKeys) => {
          if(Array.isArray(state[key][childKeys])){
            state[key][childKeys] = []
          } else {
            state[key][childKeys] = ''
          }
          state['common']['mainTabs'] = [{title: '首页', urlName: 'home'}]
        })
      })
    }
  },
  actions: {
  },
  modules: {
    common,
    user
  },
  plugins: [createPersistedState({
    storage:window.sessionStorage //想要储存到sessionStorage 就设置这个
   })]
})
