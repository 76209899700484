<template>
  <div>
    <div class="treeContent">
      <div class="left">
        <el-tree
          style="margin: 10px"
          node-key="id"
          :data="GoodsTypeList"
          :props="defaultProps"
          default-expand-all
          ref="tree"
          :highlight-current="true"
          @node-click="clickNode"
        >
        </el-tree>
      </div>
      <div class="right">
        <el-row style="margin: 10px; width: 30%">
          <el-input
            v-model="searcForm.param"
            placeholder="搜索商品名称/编号"
            @keyup.enter.native="getProduct()"
          >
            <i
              slot="prefix"
              @click="getProduct()"
              class="el-input__icon el-icon-search"
            ></i>
          </el-input>
        </el-row>
        <div>
          <el-table
            height="45vh"
            v-loading="loading"
            style="margin: 0px 10px; width: 97%"
            border
            ref="multipleTable"
            :data="ProductList"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
            row-key="id"
          >
            <el-table-column
              align="center"
              :reserve-selection="true"
              type="selection"
              width="55"
            ></el-table-column>
            <el-table-column align="center" label="编码" prop="code"
              ><template slot-scope="scope"
                ><div style="text-align: left; display: inline-block">
                  {{ scope.row.code ? scope.row.code : "-" }}
                </div>
                <span v-if="scope.row.isLimit == '1'"
                  ><i
                    style="color: red; font-size: 20px; float: right"
                    class="iconfont icon-chuangkexianzhi"
                  ></i
                ></span> </template
            ></el-table-column>
            <el-table-column align="center" label="商品名称" prop="name"
              ><template slot-scope="scope"
                ><div style="text-align: left">
                  {{ scope.row.name ? scope.row.name : "-" }}
                </div></template
              ></el-table-column
            >
            <el-table-column align="center" label="规格" prop="spec"
              ><template slot-scope="scope"
                ><div style="text-align: left">
                  {{ scope.row.spec ? scope.row.spec : "-" }}
                </div></template
              ></el-table-column
            >
            <!-- <el-table-column align="center" label="条码" prop="barCode"
              ><template slot-scope="scope"
                ><div style="text-align: left">
                  {{ scope.row.barCode ? scope.row.barCode : "-" }}
                </div></template
              ></el-table-column
            > -->
            <el-table-column align="center" label="类别" prop="typeName"
              ><template slot-scope="scope"
                ><div style="text-align: left">
                  {{ getTypeName(scope.row.typeId) }}
                </div></template
              ></el-table-column
            >
            <el-table-column align="center" label="单位" prop="unit">
              <template slot-scope="scope">
                <div style="text-align: left">
                  <div v-if="scope.row.unit">
                    {{ getUnits(scope.row) }}
                  </div>
                  <div v-else>-</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="pagination"
            @size-change="changePageSize"
            @current-change="changeCurrentPage"
            :current-page="searcForm.pageNum"
            :page-size="searcForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-row style="width: 100%; text-align: center; margin-top: 30px">
      <el-button type="primary" @click="submitData">提交</el-button>
      <el-button @click="close">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { GetProductTypeList } from "@/api/productType";
import { getProductParamList, getDetailsList } from "@/api/pesticideProduct";
import { GetRefundProductList } from "@/api/purchaseRefund";
export default {
  props: {
    isPutStorage: {
      type: Boolean,
      default: () => true,
    },
    isStatement: {
      type: Boolean,
      default: () => false,
    },
    miaWmsId: {
      type: Number,
      default: () => null,
    },
    checkArr: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      GoodsTypeList: [],
      ProductList: [],
      loading: false,
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        param: "",
        typeId: null,
        status: 0,
        wmsId: null,
      },
      total: 0,
      defaultProps: {
        children: "list",
        label: "name",
      },
      // 勾选的数据
      multipleSelection: [],
    };
  },
  watch: {
    isPutStorage(val) {
      this.isPutStorage = val;
    },
  },
  created() {
    this.getGoodsType();
    this.getProduct();
  },
  mounted() {
    if (this.isStatement) {
      // 明细表中的选择商品 要回显勾选
      if (this.checkArr.length !== 0) {
        this.$nextTick(() => {
          this.checkArr.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true); // 回显
          });
        });
      }
    }
  },
  methods: {
    // 处理多单位显示
    getUnits(data) {
      let result = data.unit;
      if (data.isMultiUnit == "1") {
        data.unitList.forEach((v) => {
          if (v.isInit == "1") {
            result += "," + v.name + "(1:" + v.value + ")";
          }
        });
      }
      return result;
    },
    getTypeName(id) {
      if (this.GoodsTypeList.length > 0) {
        return this.GoodsTypeList[1].list.filter((v) => v.id == id)[0].name;
      }
    },
    // 商品分类
    getGoodsType() {
      GetProductTypeList({}).then((res) => {
        if (res.code === 1) {
          this.GoodsTypeList = res.data.records;
          this.GoodsTypeList.unshift({ id: null, name: "全部" });
        } else {
          this.GoodsTypeList = [];
        }
      });
    },
    getProduct() {
      this.loading = true;
      //
      if (this.isPutStorage) {
        delete this.searcForm.wmsId;
        this.searcForm.status = 0;
        // 入库单
        getProductParamList(this.searcForm).then((res) => {
          if (res.code === 1) {
            const { records, current, total } = res.data;
            this.ProductList = records;
            this.total = total;
            this.searcForm.pageNum = current;
            // 当没有数据
            if (records.length === 0) {
              // 判断是否为第一页
              if (current === 1) {
                this.searcForm.pageNum = 1;
                this.loading = false;
                return;
              } else {
                // 不是回退再重新搜索
                this.searcForm.pageNum = current - 1;
                this.getProduct();
              }
            }
            this.loading = false;
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        });
      } else {
        // 退货单
        this.searcForm.wmsId = this.miaWmsId;
        delete this.searcForm.status;
        getProductParamList(this.searcForm).then((res) => {
          if (res.code === 1) {
            const { records, current, total } = res.data;
            this.ProductList = records;
            this.total = total;
            this.searcForm.pageNum = current;
            // 当没有数据
            if (records.length === 0) {
              // 判断是否为第一页
              if (current === 1) {
                this.searcForm.pageNum = 1;
                this.loading = false;
                return;
              } else {
                // 不是回退再重新搜索
                this.searcForm.pageNum = current - 1;
                this.getProduct();
              }
            }
            this.loading = false;
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        });
      }
    },
    clickNode(data, node) {
      if (!data.id) {
        this.searcForm.typeId = data.id;
        this.getProduct();
        return;
      }
      if (data.list.length) return;
      this.searcForm.typeId = data.id;
      this.getProduct();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // let ids = val.map((item) => item.id);
      // let idsStr = ids.join(",");
      // if (idsStr) {
      //   this.getDetailsList(idsStr);
      // }
    },
    async getDetailsList(ids) {
      let res = await getDetailsList({ productIds: ids });
      if (res.code == 1) {
        this.multipleSelection = res.data;
      }
    },
    // 分页
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      this.getProduct();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      this.getProduct();
    },
    submitData() {
      if (!this.isStatement) {
        if (this.multipleSelection.length !== 0) {
          this.$emit("SelectGoods", this.multipleSelection);
          console.log(this.multipleSelection);
        } else {
          this.$message.error("未选择有效商品");
        }
      } else {
        // 明细单里 选商品可以为空
        this.$emit("SelectGoods", this.multipleSelection);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #f0f7ff !important;
}
.treeContent {
  width: 100%;
  height: 60vh;
  display: inline-flex;
  justify-content: center;
  .left,
  .right {
    height: 100%;
    border: 1px solid gray;
  }
  .left {
    width: 20%;
    border-right: 0px;
    overflow: auto;
  }
  .right {
    width: 80%;
    position: relative;
    .pagination {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>
