import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { setToken, clearLoginInfo } from './auth'
import qs from 'qs'
import { Base64 } from 'js-base64'
import moment from 'moment'
// import {getConfigBill } from '@/api/user'
// import md5 from 'md5'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})
instance.interceptors.request.use(
  config => {
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
    return config
  })
// request interceptor
service.interceptors.request.use(
  config => {
    // let res = getConfigBill();
    // console.log(res);
    config.headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('token')
    // 设置get为数据时的传输方式
    if (config.method === 'get') {
      config.paramsSerializer = function(params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    // config.headers['timestamp'] = Date.now()
    // config.headers['random'] = Math.random()
    // config.headers['nonstr'] = md5(config.headers['timestamp'] + config.headers['random'] + 'yizhanggui.cc')
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    
    let contentType =
    response.headers["content-type"] || response.headers["Content-Type"];
  // 判断后端是否返回的数据流，如果是数据流，则将整个响应返回，因为需要从header中取文件名
  if (contentType.indexOf("application/octet-stream") > -1) {
    const contentDisposition =
    response.headers["content-disposition"] || response.headers["Content-Disposition"];
    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    let matches = filenameRegex.exec(contentDisposition)
    let fileName = '';
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '')
    }
    response.fileName = decodeURI(fileName);
    return response;
  } 
  const res = response.data
    if (res.code === -1) {
      return res
    }
    // do something before request is sent
    const expTime = sessionStorage.getItem('expirationTime')
    const nowTime = new Date().getTime()

    if (expTime - nowTime > 0 && expTime - nowTime < 5 * 60 * 1000) {
      // token在5分钟内过期时重新获取token
      instance({
        url: '/refresh/token',
        method: 'get'
      }).then(res => {
        sessionStorage.setItem('token', res.data && res.data.data && res.data.data.token)
        const exp = JSON.parse(Base64.decode(res.data.data.token.split('.')[1])).exp
        sessionStorage.setItem('expirationTime', moment(moment.unix(exp)).valueOf())
      })
    }
    // if the custom code is not 1011, it is judged as an error.
    if (res.code !== 1) {
      if (!res.code) {
        return res
      }
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 2) {
        // to re-login
        MessageBox.confirm('登录失效，请重新登录', '重新登录', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          clearLoginInfo()
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
      // return res
    } else {
      return res
    }
  },
  error => {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Message({
        message: '网络请求超时',
        type: 'error',
        duration: 5 * 1000
      })
    }
    if (error.toString().indexOf('Error: Network Error') !== -1) {
      Message({
        message: '网络请求错误',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
