<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :center="true"
        :destroy-on-close="true"
        :width="width">
        <slot v-if="dialogVisible" name="content" />
    </el-dialog>
</template>
<script>
export default {
    name: 'miaDialog',
    props:{
       width: {
      type: String,
      default() {
        return '50%'
      }
    },
    },
    data(){
        return{
            dialogVisible: false,
            title: ''
        }
    },
    methods:{
        init(title){
            this.dialogVisible = true
            this.title = title
        },
        close(){
            this.dialogVisible = false
        }
    }
}
</script>
<style lang="scss" scoped>
.el-dialog__body{
    padding-bottom: 0px;
}
</style>