module.exports = {

  title: '',

  // 图片上传地址
  uploadUrl: process.env.VUE_APP_BASE_API + '/base/file/upload',
  openUploadUrl: process.env.VUE_APP_BASE_API + '/api/file/upload',
  imgUrl: process.env.VUE_APP_IMAGE_API,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: false
}
