<template>
  <div>
    <el-row style="margin-bottom: 20px;width:96%;text-align: right;">商品名称：{{this.data.producName}} 单位：{{this.data.unit}}</el-row>
    <el-table
      height="50vh"
      style="margin: 0px 10px; width: 97%"
      border
      ref="multipleTable"
      :data="PriceList"
      tooltip-effect="dark"
      row-key="id"
    >
      <el-table-column
        align="center"
        v-for="(item, index) in tableItem"
        :key="index"
        :label="item.label"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <div v-if="item.type !== 'price'" style="text-align: left">
            {{ scope.row[item.prop] ? scope.row[item.prop] : "-" }}
          </div>
          <div v-else>
            <span v-if="scope.row[item.prop]">
              {{ scope.row[item.prop] | backPrice }}
            </span>
            <span v-else>0.00</span>
          </div>
        </template></el-table-column
      >
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" :disabled="!scope.row['price']" @click="rowClick(scope.row)">选中</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-row style="width: 100%; text-align: center; margin-top: 30px">
      <el-button @click="close">取消</el-button>
    </el-row>
  </div>
</template>
<script>
import { GetPriceList } from "@/api/putstorage";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  data() {
    return {
      searchForm: {},
      PriceList: [],
      tableItem: [
        { prop: "type", label: "类型", type: "default" },
        { prop: "price", label: "单价", type: "price" },
        { prop: "remark", label: "备注", type: "default" },
      ],
    };
  },
  created() {
    this.searchForm.productId = this.data.productId;
    this.searchForm.unitId = this.data.unitId;
    this.getData()
  },
  methods: {
    getData() {
      GetPriceList(this.searchForm).then((res) => {
        if (res.code === 1) {
          this.PriceList = res.data;
        } else {
          this.PriceList = [];
        }
      });
    },
    rowClick(row, column, event) {
      this.$emit("SelectPrice", row);
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>