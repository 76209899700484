import request from '@/until/request.js'
const prefixName = '/gm/wmsInfoInitial'
export function GetExpectList(data){
    return request({
        url: '/gm/webInitial/prd',
        method: 'get',
        params: { ...data }
    })
}
export function SaveInstall(result){
    return request({
        url: '/gm/webInitial/prd',
        method: 'post',
        data: result
      })
}
export function search(data){
    return request({
        url: prefixName + '/checkCode',
        method: 'get',
        params: { ...data }
    })
}
export function GetDetail(data){
    return request({
        url: '/gm/webInitial/prd/detail',
        method: 'get',
        params: { ...data }
    })
}