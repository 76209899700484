<template>
  <div>
      <div class="top">
          <div class="left">
              <el-input style="wiidth:100%" v-model="sreachForm.batchNo" placeholder="搜索批次号" >
                  <i slot="suffix" @click.stop="GetList" class="el-input__icon el-icon-search"></i>
              </el-input>
          </div>
          <div class="right">
              <span style="margin-right:20px">商品名称：{{ data.producName }}</span>
              <span>仓库：{{ miaWmsId  | backName(WmsList)}}</span>
          </div>
      </div>
      <div class="bottom">
          <el-table height="50vh" style="margin:0px 10px;width:97%" border ref="multipleTable" :data="batchList" tooltip-effect="dark" @selection-change="handleSelectionChange" row-key="id">
            <el-table-column align="center" :reserve-selection="true" type="selection" width="55"></el-table-column>
            <el-table-column align="center" v-for="(item,index) in tableItem" :key="index" :label="item.label" :prop="item.prop">
              <template slot-scope="scope">
              <div style="text-align:left" v-if="item.prop !== 'dateDue' && item.prop !== 'num'">{{ scope.row[item.prop] ? scope.row[item.prop] : '-' }}</div>
              <div style="text-align:left" v-if="item.prop === 'num'">{{ scope.row[item.prop] ? scope.row[item.prop] : '0' }}</div>
              <div v-if="item.prop === 'dateDue'" style="text-align:left"  :style="comparDate(scope.row[item.prop]) ? {color: 'red'} : {}">{{ scope.row[item.prop] ? scope.row[item.prop] : '-' }}</div>
              </template>
              </el-table-column>
        </el-table>
        <el-pagination
        class="pagination"
        @size-change="changePageSize"
        @current-change="changeCurrentPage"
        :current-page="sreachForm.pageNum"
        :page-size="sreachForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
      </div>
      <el-row style="width: 100%; text-align: center; margin-top: 30px">
            <el-button type="primary" @click="submitData">提交</el-button>
            <el-button @click="close">取消</el-button>
   </el-row>
  </div>
</template>
<script>
import { GetBatchList } from "@/api/putstorage";
import { GetWmsList } from "@/api/warehouse";
export default {
  props: {
    miaWmsId: {
      type: Number,
      default: () => 0,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  filters:{
      backName(val,list) {
          if(val) {
              const element =  list.filter(item => { return item.id === val })
              if(element.length !== 0) {
                  return element[0].name
              } else {
                  return ''
              }
          }
          return ''
      }
  },
  data() {
    return {
      sreachForm: {
        pageNum: 1,
        pageSize: 10,
        productId: "",
        batchNo: "",
        wmsId: "",
      },
      batchList: [],
      tableItem:[
          { prop: "batchNo", label: "批次号", type: "default" },
          { prop: "manufactureDate", label: "生产日期", type: "default" },
          { prop: "expire", label: "保质期天数", type: "default" },
          { prop: "dateDue", label: "到期日期", type: "default" },
          { prop: "num", label: "库存数量", type: "default" },
      ],
      total: 0,
      SelectList:[],
      WmsList:[]
    };
  },
  created() {
    this.sreachForm.wmsId = this.miaWmsId;
    this.sreachForm.productId = this.data.productId;
    this.GetList()
    this.getWmsList()
  },
  methods: {
    GetList() {
      GetBatchList(this.sreachForm).then((res) => {
        if (res.code === 1) {
          const { records, current, total } = res.data;
          this.batchList = records.map((val,index) => {
            val.index = index
              if(val.manufactureDate && val.expire){
                  val.dateDue = this.getNewDay(val.manufactureDate,val.expire)
              } else {
                  val.dateDue = ''
              }
              return val
          });
          this.total = total;
          this.sreachForm.pageNum = current;
          // 当没有数据
          if (records.length === 0) {
            // 判断是否为第一页
            if (current === 1) {
              this.sreachForm.pageNum = 1;
              return;
            } else {
              // 不是回退再重新搜索
              this.sreachForm.pageNum = current - 1;
              this.GetList();
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsList = res.data.records;
        } else {
          this.WmsList = [];
        }
      });
    },
    // 计算到期日期
    getNewDay(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
        // 分页
    changePageSize(val) {
      this.sreachForm.pageSize = val;
      this.sreachForm.pageNum = 1;
      this.GetList();
    },
    changeCurrentPage(val) {
      this.sreachForm.pageNum = val;
      this.GetList();
    },
    // 多选批次
    handleSelectionChange(val) {
        this.SelectList = val
    },
    // 判断是否过期
    comparDate(dateValue) {
    return new Date().getTime() >= new Date(dateValue).getTime() + 3600 * 1000 * 24;
  },
    submitData(){
        if(this.SelectList.length !== 0) {
            this.$emit('SelectBatchGoods',this.SelectList, this.data)
        } else {
            this.$message.error('请选择需要出库的批次')
        }
    },
    close(){
        this.$emit('close')
    }
  },
};
</script>
<style lang="scss" scoped>
.top{
    display: inline-flex;
    width: 97%;
    justify-content: space-between;
    margin: 0px 10px 20px 10px;
    .left{
        width: 30%;

    }
    .right{
        width: 60%;
        display: inline-flex;
        align-items: center;
        justify-content: right;
    }
}
.bottom{
    height: 55vh;
    position: relative;
    .pagination{
        width: 100%;
        text-align: center;
        margin-top: 10px;
        position: absolute;
        bottom: 0px;
    }
}
</style>