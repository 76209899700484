<template>
  <div class="custom-form">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      :label-width="lableWidth"
    >
      <template v-for="(item, index) in formFields">
        <!-- 分类文字 -->
        <div
          v-if="item.type == 'interleave'"
          :key="'interleave' + index"
          class="form-interleave"
        >
          {{ item.name }}
        </div>
        <!-- 验证码 -->
        <el-form-item
          v-if="item.type === 'verification'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="item.colSpan ? item.colSpan : null">
            <div class="verificationInput">
              <el-input
              v-model="form[item.field]"
              :name="item.field"
              :disabled="item.disabled"
              :placeholder="
                item.showPlaceholder ? '请输入' + item.name : item.placeholder
              "
            />
            <slot name="verificationButton"></slot>
            </div>
          </el-col>
          <el-row>
             <slot name="smartCaptcha"></slot>
          </el-row>
        </el-form-item>
        <!-- //普通input -->
        <el-form-item
          v-if="!item.type || item.type == 'input'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="item.colSpan ? item.colSpan : null">
            <el-input
              v-model="form[item.field]"
              :name="item.field"
              :disabled="item.disabled"
              :placeholder="
                item.showPlaceholder ? '请输入' + item.name : item.placeholder
              "
            />
            <span v-if="item.msg" style="color: #e6a23c">{{ item.msg }}</span>
          </el-col>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item
          v-if="item.type == 'pwd'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col>
            <el-input
              v-model="form[item.field]"
              :placeholder="
                item.showPlaceholder ? '请输入' + item.name : item.placeholder
              "
              :name="item.field"
              :disabled="item.disabled"
              show-password
            />
          </el-col>
        </el-form-item>
        <!-- 数字input -->
        <el-form-item
          v-if="item.type == 'number'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-input-number
              v-model="form[item.field]"
              :precision="item.precision || 0"
              :min="item.min || 0"
              :max="item.max || 10000"
              :label="item.field"
              :placeholder="
                item.showPlaceholder ? '请输入' + item.name : item.placeholder
              "
              :controls="false"
            />
          </el-col>
        </el-form-item>
        <!-- 只显示值 -->
        <el-form-item
          v-if="item.type == 'justShow'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            {{ form[item.field] }}
          </el-col>
        </el-form-item>
        <el-form-item
          v-if="item.type === 'rate'"
          :key="index"
          :label="item.name + '：'"
          :prop="item.field"
        >
          <el-col
            :span="12"
            style="height: auto; word-wrap: break-word; word-break: normal"
          >
            <el-rate
              v-model="form[item.field]"
              style="height: 20px; line-height: 2.5"
            />
          </el-col>
        </el-form-item>
        <!-- 地址 -->
        <el-form-item
          v-if="item.type == 'mapDistpicker'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <v-distpicker @selected="selected"></v-distpicker>
          <el-input
             type="textarea"
             :style="{marginTop: '20px'}"
             :rows="2"
             :placeholder="
                item.showPlaceholder ? '请输入' + item.name : item.placeholder
              "
             v-model="form[item.field]">
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="item.type == 'date'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="item.colSpan ? item.colSpan : null">
            <el-date-picker
              v-model="form[item.field]"
              :picker-options="item.disabledDate"
              type="date"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :placeholder="item.placeholder || '选择日期'"
              :disabled-date="item.disabledDate"
            />
          </el-col>
        </el-form-item>
        <!-- 时间选择器 -->
        <el-form-item
          v-if="item.type == 'time'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-time-picker
              v-model="form[item.field]"
              :placeholder="item.placeholder || '选择时间'"
              :disabled-date="item.disabledDate"
            />
          </el-col>
        </el-form-item>
        <!-- 单选下拉 -->
        <el-form-item
          v-if="item.type == 'select'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="item.colSpan ? item.colSpan : null">
            <el-select
              v-model="form[item.field]"
              style="width: 100%"
              filterable
              clearable
              :placeholder="'请选择' + item.name"
              :disabled="item.disabled"
              @change="
                item.selectChange
                  ? item.selectChange($event, item)
                  : selectChange($event, item)
              "
            >
              <el-option
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-col>
          <!-- tip提示 -->
          <el-tooltip
            v-if="item.tipContent"
            :key="'tip' + index"
            effect="dark"
            placement="bottom"
          >
            <div slot="content" v-html="item.tipContent" />
            <i class="el-icon-question form-tip-icon" />
          </el-tooltip>
        </el-form-item>
        <!-- 下拉多选 -->
        <el-form-item
          v-if="item.type == 'multiple'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="item.colSpan ? item.colSpan : null">
            <el-select
              v-model="form[item.field]"
              filterable
              style="width: 100%"
              :placeholder="'请选择' + item.name"
              :disabled="item.disabled"
              multiple
              @change="selectChange($event, item)"
            >
              <el-option
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-col>
        </el-form-item>
        <!-- radio -->
        <el-form-item
          v-if="item.type == 'radio'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-radio-group
              v-model="form[item.field]"
              @change="radioChange($event, item.field)"
            >
              <el-radio
                v-for="(option, optionIndex) in item.options"
                :key="optionIndex"
                :label="option.value"
              >{{ option.label }}</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
        <!-- checkbox -->
        <el-form-item
          v-if="item.type == 'checkbox'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-checkbox-group v-model="form[item.field]">
              <el-checkbox
                v-for="(option, i) in item.options"
                :key="i"
                :label="option.label"
                :checked="option.checked"
              />
            </el-checkbox-group>
          </el-col>
        </el-form-item>
        <!-- textarea -->
        <el-form-item
          v-if="item.type == 'textarea'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-input
              v-model="form[item.field]"
              :maxlength="item.maxlength || 255"
              type="textarea"
              :disabled="item.disabled"
              :placeholder="item.placeholder || ''"
              :autosize="{ minRows: 3 }"
              show-word-limit
            />
          </el-col>
        </el-form-item>
        <!-- cascader -->
        <el-form-item
          v-if="item.type == 'cascader'"
          :key="index"
          class="cascader-item"
          :label="item.name"
          :prop="item.field"
        >
          <el-col :span="11">
            <el-cascader
              v-model="form[item.field]"
              style="width: 100%"
              :options="item.options"
              :placeholder="item.placeholder"
              :props="item.props"
            />
          </el-col>
        </el-form-item>
        <!-- tree -->
        <el-form-item
          v-if="item.type == 'tree'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <slot name="treeSelect" />
        </el-form-item>
        <!-- file -->
        <el-form-item
          v-if="item.type == 'file'"
          :key="index"
          :label="item.name"
          :prop="item.field"
        >
          <el-col v-if="!item.disabled || item.fileList.length > 0" :span="11">
            <el-upload
              :class="{
                hide: (item.limit ? item.limit : 1) === item.fileList.length,
              }"
              :action="uploadUrl"
              multiple
              :limit="item.limit || 1"
              :disabled="item.disabled"
              :on-exceed="fileExceed"
              :on-success="
                (res, file, fileList) =>
                  fileSuccess(res, file, fileList, item.field)
              "
              :on-remove="
                (file, fileList) =>
                  fileRemove(file, fileList, item.field, index)
              "
              :file-list="item.fileList"
              :before-upload="beforeFileUpload"
              :on-preview="onPreview"
              :headers="{ Authorization: 'Bearer ' + token }"
              @click="uploadFile"
            >
              <el-button
                v-if="(item.limit ? item.limit : 1) !== item.fileList.length"
                size="small"
                type="primary"
                @click="imageUpload(index)"
              >点击上传</el-button>
            </el-upload>
          </el-col>
          <el-col v-if="item.fileList.length === 0 && item.disabled" :span="11">
            <span style="color: rgb(153, 153, 153)">暂无文件</span>
          </el-col>
        </el-form-item>
        <!-- img -->
        <el-form-item
          v-if="item.type == 'img'"
          :key="index"
          :label="item.name"
          :prop="item.field"
          :class="item.costomClass"
        >
          <el-col
            v-if="!item.disabled || item.imageList.length > 0"
            :span="20"
            style="display: flex"
          >
            <div class="img-box">
              <template v-for="(img, n) in item.imageList">
                <div :key="n" style="position: relative">
                  <img :key="n" :src="img.src" class="show-img">
                  <div :key="n + 'icon'" class="img-in-box">
                    <i
                      class="el-icon-view img-in-icon"
                      @click="
                        dialogVisible = true;
                        dialogImageUrl = img.src;
                      "
                    />
                    <i
                      v-if="!item.disabled"
                      class="el-icon-delete img-in-icon"
                      @click="imagehandleRemove(item.field, n, index)"
                    />
                  </div>
                </div>
              </template>
              <el-upload
                ref="ElUpload"
                class="uploader"
                :action="item.open ? openUploadUrl : uploadUrl"
                :disabled="item.disabled"
                :show-file-list="false"
                :on-success="
                  (res, file, fileList) =>
                    imageSuccess(res, file, fileList, item.field)
                "
                :on-remove="imageRemove"
                :limit="item.limit || 1"
                :on-error="imageError"
                :on-progress="imageProgress"
                :file-list="item.imageList"
                :before-upload="beforeUpload"
                :headers="{ Authorization: 'Bearer ' + token }"
              >
                <el-progress
                  v-if="imgLoadStatus === 'uploading'"
                  :type="(listType = 'circle')"
                  :width="100"
                  style="position: absolute; top: 0; background: #fff"
                  :percentage="parsePercentage(percentage)"
                />
                <i
                  v-if="
                    (item.limit ? item.limit : 1) !== item.imageList.length &&
                      !item.disabled
                  "
                  class="el-icon-plus uploader-icon"
                  @click="imageUpload(index)"
                />
              </el-upload>
            </div>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt>
            </el-dialog>
          </el-col>
          <el-col
            v-if="item.imageList.length === 0 && item.disabled"
            :span="11"
          >
            <span style="color: rgb(153, 153, 153)">暂无图片</span>
          </el-col>
        </el-form-item>
        <!-- 表单单项说明文字 -->
        <el-form-item v-if="item.afterTxt" :key="index + 'afterTxt'">
          <el-col :span="11" class="form-afterTxt" v-html="item.afterTxt" />
        </el-form-item>
        <!-- 表单单项说明文字 -->
        <el-form-item v-if="item.afterDom" :key="index + 'afterDom'">
          <el-col :span="11" class="form-afterDom" v-html="item.afterDom" />
        </el-form-item>
        <!-- 带跳转的提示文字 -->
        <el-form-item v-if="item.isShowLink" :key="index + 'linkHref'">
          <el-col
            :span="11"
            style="font-size: 12px; line-height: 1.5; color: #999"
          >1.根据相关规定，已备案的食品安全管理员、食品安全从业人员须在系统内答题考试，如果以上人员未进行备案，请先进行<a
            style="color: #409eff"
            @click="item.linkHandle"
          >人员备案</a>，再创建员工。 <br>2.其余人员可不选择此项。</el-col>
        </el-form-item>
        <!--  下载提示       -->
        <el-form-item v-if="item.type == 'uploadTip'" :key="index + 'uploadTip' "><el-col :span="9" style="font-size: 14px;color: red">{{ item.content }}</el-col></el-form-item>
      </template>
      <slot name="registerTip"></slot>
      <el-form-item>
        <el-button
          v-if="isShowSave"
          type="primary"
          @click="onSubmit"
        >{{SaveButtonText}}</el-button>
        <el-button v-if="isShowCancel" @click="onCancel">取消</el-button>
      </el-form-item>
      <el-form-item v-if="buttonArray.length!==0" style="margin-left: 10px" :class="buttonArray.length!==0 ? 'hasButton' : ''">
        <el-button v-for="(button,index) in buttonArray" :key="index" :type="button.type" :size="button.size" :disabled="button.disabled" @click="button.click($refs.form)">{{ button.name }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import setting from '@/until/settings.js'
import VDistpicker from 'v-distpicker'
export default {
  name: 'SimpleForm',
  props: {
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    formFields: {
      type: Array,
      default() {
        return []
      }
    },
    buttonArray: {
      type: Array,
      default() {
        return []
      }
    },
    rules: {
      type: Object,
      default() {
        return {}
      }
    },
    ClickSendFlag: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    isShowSave: {
      type: Boolean,
      default: true
    },
    isShowCancel: {
      type: Boolean,
      default: true
    },
    // 文件大小
    fileSize: {
      type: Number,
      default: 3
    },
    // 图片大小
    imgSize: {
      type: Number,
      default: 5
    },
    // labewidth
    lableWidth: {
      type: String,
      default: '80px'
    },
    // 表单提交文字
    SaveButtonText:{
      type: String,
      default: '保存'
    },
    verificationText:{
      type: String,
      default: '发送验证码'
    }
  },
  data() {
    return {
      fileList: [],
      focusing: [],
      percentage: 0,
      uploadUrl: '',
      openUploadUrl: '',
      imageList: [],
      imgLoadStatus: '',
      imgUploadIndex: '',
      dialogImageUrl: '',
      dialogVisible: false,
      token: ''
    }
  },
  components: { VDistpicker },
  mounted() {
    this.uploadUrl = setting.uploadUrl
    this.openUploadUrl = setting.openUploadUrl
  },
  methods: {
    // 表单保存
    onSubmit() {
      this.$emit('onSubmit', this.$refs.form, this.form)
    },
    onPreview(fileList) {
      this.$emit('onPreview', fileList)
    },
    // 表单返回
    onCancel() {
      this.$router.go(-1)
    },
    // 选择地址
    selected(data){
      this.$emit('selected', data)
    },
    // 发送验证码
    sendCode(){
      this.$emit('sendCode')
    },
    // 表单select元素改变时触发
    selectChange(changed, data) {
      this.$emit('selectChange', changed, data)
    },
    radioChange(changed, field) {
      this.$emit('radioChange', changed, field)
    },
    fileExceed(files, fileList) {
      this.$message.error(`只能上传 ${this.formFields[this.imgUploadIndex].limit || 1} 个文件`)
    },
    // 表单上传图片成功时触发
    imageSuccess(res, file, fileList, field) {
      this.imgLoadStatus = file.status
      // 此处上传图片判断接口是否成功上传
      if (res.code === 1) {
        file.src = URL.createObjectURL(file.raw)
        this.$refs['form'].clearValidate(field)
        this.$emit('imgUploadSuc', file, fileList, this.imgUploadIndex, field)
        this.$message.success('上传成功')
        // 上传成功时将地址放入form(等待接口完善)
      } else {
        this.$message.error(res.msg)
      }
    },
    fileSuccess(res, file, fileList, field) {
      // 此处上传文件判断接口是否成功上传
      if (res.code === 1) {
        file.src = URL.createObjectURL(file.raw)
        this.$emit('fileUploadSuc', file, fileList, this.imgUploadIndex, field)
        this.$message.success('上传成功')
        // 上传成功时将地址放入form(等待接口完善)
      } else {
        this.$message.error(res.msg)
      }
    },
    imageRemove(file, fileList) {
      // this.$emit('imageRemove', fileList, this.imgUploadIndex)
    },
    fileRemove(file, fileList, field, index) {
      this.$emit('fileRemove', file, fileList, field, index)
    },
    imageProgress(event, file, fileList) {
      this.percentage = file.percentage
      this.imgLoadStatus = file.status
    },
    imageUpload(e) {
      this.imgUploadIndex = e
    },
    imagehandleRemove(field, n, index) {
      // this.$refs['ElUpload'][0].handleRemove()
      this.$emit('imageRemove', field, index, n)
      // this.formFields[index].imageList.splice(n, 1)
    },
    imageError() {
      this.$message.error('网络错误')
    },
    uploadFile(e) {
      // 下载附件
      top.window.location = setting.imgUrl + e.file
    },
    parsePercentage(val) {
      return parseInt(val, 10)
    },
    clearValidate(field) {
      this.$refs['form'].clearValidate(field)
    },
    beforeUpload(file) {
      // 此处可自定义上传图片的格式大小
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < this.imgSize
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG/png 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 ' + this.imgSize + 'MB!')
      }
      return isJPG && isLt2M
    },
    beforeFileUpload(file) {
      // 此处可自定义上传文件的格式大小
      // file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      const arr = file.name.split('.')
      const isWord = file.type === 'application/zip' ||
      file.type === 'application/msword' ||
      file.type === 'application/pdf' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || arr[arr.length - 1].toLowerCase() === 'rar' || arr[arr.length - 1].toLowerCase() === 'ppt' || arr[arr.length - 1].toLowerCase() === 'pptx' || arr[arr.length - 1].toLowerCase() === 'xls' || arr[arr.length - 1].toLowerCase() === 'xlsx'
      const isLt2M = file.size / 1024 / 1024 < this.fileSize
      if (!isWord) {
        this.$message.error('上传文件只能是 zip/rar/docx/doc/pdf/excel/ppt 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 ' + this.fileSize + 'MB!')
      }
      return isWord && isLt2M
    }
  }
}
</script>
<style lang="scss" scope>
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploader .el-upload:hover {
  border-color: #409eff;
}
.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.show-img {
  width: 100px;
  height: 100px;
  display: block;
}
.img-box {
  display: flex;
  flex-wrap: wrap;
  img {
    margin: 0 10px 10px 0;
  }
}
.form-afterTxt {
  font-size: 12px;
  line-height: 1.5;
  color: #999;
}
.img-in-box {
  transition: all 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0);
  text-align: center;
  line-height: 100px;
  .img-in-icon {
    opacity: 0;
    padding: 5px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
}
.img-in-box:hover {
  background: rgba(0, 0, 0, 0.5);
  .img-in-icon {
    opacity: 1;
  }
}
.custom-form {
  .el-form-item {
    //margin-left: 100px;
    margin-bottom: 22px;
  }
  .el-textarea .el-input__count {
    background-color: rgba(0, 0, 0, 0);
  }
}
.form-tip-icon {
  margin-left: 10px;
  color: #999;
  font-size: 18px;
}
.el-input-number {
  width: 100%;
  input {
    text-align: left !important;
  }
}
.verificationInput{
  position: relative;
  .sendCode{
    position: absolute;
    right: 3%;
  }
}
.cascader-item {
  .el-input__inner {
    &::placeholder {
      color: #606266;
    }

    &::-webkit-input-placeholder {
      /* WebKit browsers 适配谷歌 */
      color: #606266;
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 适配火狐 */
      color: #606266;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ 适配火狐 */
      color: #606266;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10+  适配ie*/
      color: #606266;
    }
  }
}
</style>
