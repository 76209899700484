<template>
    <div class="mia-navback">
        <div class="icon"  @click="back"><i v-if="isShowBack" class="el-icon-arrow-left"></i></div>
        <div class="button">
            <el-button v-if="isShowCaogao" type="default" round @click="caogao"> <i class="el-icon-circle-check" /> 存草稿 </el-button>
            <el-button v-if="isShowSave" type="primary" round @click="submit"> <i class="el-icon-circle-check" /> 提交</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name:'mia-navback',
    props:{
        // 是否展示提交按钮
        isShowSave:{
            type:Boolean,
            default: true
        },
        // 是否展示返回按钮
        isShowBack:{
            type:Boolean,
            default: true
        },
        isShowCaogao:false,
    },
    methods:{
        submit(){
            this.$emit('submit')
        },
        caogao(){
            this.$emit('caogao')
        },
        back(){
            this.$emit('back')
        }
    }
}
</script>
<style lang="scss" scoped>
.mia-navback{
    width: 96%;
    height: 69px;
    padding: 0 2%;
    display: inline-flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    .icon{
        cursor: pointer;
    }
    border-bottom: 1px solid #E8E8E8;
    // background-color: cadetblue;
    .el-button.is-round {
    border-radius: 20px;
    padding: 10px 12px;
    }
}
</style>