import request from '@/until/request.js'
const prefixName = '/gm/webWms'
// 获取仓库列表
export function GetWmsList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
// 创建仓库
export function AddWms(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 修改仓库
export function EditWms(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 修改仓库
export function DeleteWms(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}