import { render, staticRenderFns } from "./selectPrice.vue?vue&type=template&id=7a88ba1d&"
import script from "./selectPrice.vue?vue&type=script&lang=js&"
export * from "./selectPrice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports