import { getToken } from '../../until/auth'
export default {
    namespaced: true,
    state: {
      name: '',
      userId: 0,
      userName: '',
      officeId: '',
      officeName: '',
      officeType: '',
      photo: '',
      token: ''
    },
    mutations: {
      updateId (state, userId) {
        state.userId = userId
      },
      updateName (state, name) {
        state.name = name
      },
      updateuserName (state, userName) {
        state.userName = userName
      },
      updatePhoto (state, photo) {
        state.photo = photo
      },
      updateOfficeId (state, updateOfficeId) {
        state.updateOfficeId = updateOfficeId
      },
      updateOfficeName (state, updateOfficeName) {
        state.updateOfficeName = updateOfficeName
      },
      updateOfficeType (state, officeType) {
        state.officeType = officeType
      },
      updataToken (state, token) {
        state.token = token  
      }
    }
  }