<template>
  <el-row type="flex">
    <el-col :span="24">
      <el-table
        :data="tableData"
        highlight-current-row
        border
        style="width: 100%"
        size="mini"
        @cell-click="tabClick"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
      >
        <el-table-column align="center" label="序号" width="50">
          <template scope="scope">
            <span>{{ scope.$index + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-for="(item, index) in tableColumn"
          :class-name="item.edit ? 'editColumn' : 'forbidColumn'"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.width ? item.width : '160px'"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.required" style="color: red; margin-right: 5px"
              >*</span
            ><span>{{ item.label }}</span>
          </template>
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.index === clickRow &&
                scope.column.index === clickCell &&
                item.edit
              "
            >
              <el-input
                v-if="item.type === 'input'"
                ref="editInput"
                v-model="scope.row[item.prop]"
                size="mini"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                @blur="inputBlur($event, scope.row)"
              />
              <el-input-number
                v-if="item.type === 'number' || item.type === 'pirce'"
                v-model="scope.row[item.prop]"
                controls-position="right"
                :min="item.min"
                :max="item.max"
                size="mini"
                @blur="inputBlur"
                @change="() => inputChange(scope.row)"
                :precision="item.precision"
              ></el-input-number>
              <!-- 普通商品禁用 -->
              <el-select
                v-if="item.type === 'select' && type === 0"
                v-model="scope.row[item.prop]"
                placeholder="请选择"
                size="mini"
                @change="ChangeSelect"
              >
                <el-option
                  v-for="item in item.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
              <!-- 批次商品 -->
              <el-select
                v-if="item.type === 'select' && type === 1"
                v-model="scope.row[item.prop]"
                placeholder="请选择"
                size="mini"
                @change="ChangeSelectBatch($event, scope.row.index)"
              >
                <el-option
                  v-for="item in item.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <!-- 序列码商品 -->
              <el-select
                v-if="item.type === 'select' && type === 2"
                v-model="scope.row[item.prop]"
                placeholder="请选择"
                size="mini"
                @change="ChangeSelect"
              >
                <el-option
                  v-for="item in item.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-if="item.type === 'date'"
                v-model="scope.row[item.prop]"
                type="date"
                size="mini"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @change="() => changeDate(scope.row)"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <div v-else style="text-align: right">
              <span v-if="item.type === 'pirce'">{{
                scope.row[item.prop] | backPrice
              }}</span>
              <span v-if="item.type === 'select'">
                {{ scope.row[item.prop] | backWms(WmsData) }}
              </span>
              <span v-if="item.type !== 'select' && item.type !== 'pirce'">{{
                scope.row[item.prop]
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="addData(scope.$index)"
              >插入</el-button
            >
            <el-button type="text" @click="deleteData(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col v-if="type === 2 && serialNoList.length !== 0" :span="24" :push="1">
      <el-table
        :data="serialNoList"
        highlight-current-row
        border
        style="width: 100%"
        size="mini"
        @cell-click="serialNoClick"
        :row-class-name="serialNoRowClassName"
        :cell-class-name="serialNollClassName"
      >
        <el-table-column align="center" label="序号" width="50">
          <template scope="scope">
            <span>{{ scope.$index + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          v-for="(item, index) in serialColumn"
          :class-name="item.edit ? 'editColumn' : 'forbidColumn'"
          :key="index"
          :width="item.width ? item.width : '160px'"
          :label="item.label"
          :prop="item.prop"
          :show-overflow-tooltip="true"
        >
          <template slot="header" slot-scope="scope">
            <span v-if="item.required" style="color: red; margin-right: 5px"
              >*</span
            ><span>{{ item.label }}</span>
          </template>
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.index === serialNoRow &&
                scope.column.index === serialNoCell &&
                item.edit
              "
            >
              <el-input
                v-if="item.type === 'input'"
                ref="editInput"
                v-model="scope.row[item.prop]"
                size="mini"
                onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                @change="(val) => handleChange(val, scope)"
              />
              <el-input-number
                v-if="item.type === 'number' || item.type === 'pirce'"
                v-model="scope.row[item.prop]"
                controls-position="right"
                :min="item.min"
                :max="item.max"
                size="mini"
                @change="() => inputChange(scope.row)"
                :precision="item.precision"
              ></el-input-number>
              <el-select
                v-if="item.type === 'select'"
                v-model="scope.row[item.prop]"
                placeholder="请选择"
                size="mini"
                @change="ChangeSelect"
              >
                <el-option
                  v-for="item in item.data"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-if="item.type === 'date'"
                v-model="scope.row[item.prop]"
                type="date"
                size="mini"
                style="width: 100%"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                @change="() => changeDate(scope.row)"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
            <div v-else style="text-align: right">
              <span v-if="item.prop === 'remark'" style="color: red">{{
                scope.row[item.prop]
              }}</span>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="160px">
          <template slot-scope="scope">
            <el-button type="text" @click="addserialNoRow(scope.$index)"
              >插入</el-button
            >
            <el-button type="text" @click="deleteserialNoRow(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
import { GetWmsList } from "@/api/warehouse";
import { search } from "@/api/expectInstall";
export default {
  name: "Editable",
  props: {
    tableColumn: {
      type: Array,
      default: () => [],
    },
    expire: {
      type: Number,
      default: () => 0,
    },
    type: {
      type: Number,
      default: () => 0,
    },
    miaTable: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      clickRow: null, // 当前点击的行
      clickCell: null, // 当前点击的列
      tabClickLabel: "", // 当前点击的列名
      serialNoRow: null,
      serialNoCell: null, // 当前点击的列
      serialNoClickLabel: "", // 当前点击的列名
      tableData: [],
      WmsData: [],
      serialNoList: [],
      // 选择过的仓库
      selectList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      serialColumn: [
        {
          prop: "code",
          label: "序列码",
          type: "input",
          edit: true,
          required: true,
        },
        {
          prop: "remark",
          label: "备注",
          edit: false,
          required: false,
        },
      ],
    };
  },
  filters: {
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    backWms(val, list) {
      if (val === null) return "";
      const name = list.filter((item) => {
        return item.id === val;
      });
      if (name.length !== 0) {
        return name[0].name;
      } else {
        return "";
      }
    },
  },
  watch: {
    miaTable(val) {
      if (val.length !== 0) {
        this.changeTable(val);
      }
    },
  },
  created() {
    this.getWms();
    this.tableData = [];
    // 初始化数据 
    for (var i = 0; i < 10; i++) {
      let item = new Object();
      if (this.type === 0) {
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.initialAverageCost = 0;
      } else if (this.type === 1) {
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.batchNo = null;
        item.productionDate = null;
        item.initialAverageCost = 0;
        item.expire = this.expire;
      } else if (this.type === 2) {
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.initialAverageCost = 0;
        item.serialNoList = item.serialNoList = new Array(3)
          .fill(new Object({ code: "", remark: "" }))
          .map((val) => {
            return { ...val };
          });
      }
      this.tableData.push(item);
    }
  },
  methods: {
    // 获取仓库
    getWms() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsData = res.data.records;
        } else {
          this.WmsData = [];
        }
      });
    },
    // 回显数据
    changeTable(list) {
      if (list.length !== 0) {
        for (let i in list) {
          const element = list[i];
          let item = {};
          if (this.type === 0) {
            item.wmsId = element.wmsId;
            item.initialNumber = element.initialNumber;
            item.initialCost = element.initialCost;
            item.initialAverageCost = element.initialAverageCost;
          } else if (this.type === 1) {
            item.wmsId = element.wmsId;
            item.initialNumber = element.initialNumber;
            item.initialCost = element.initialCost;
            item.batchNo = element.batchNo;
            item.productionDate = element.manufactureDate;
            item.initialAverageCost = element.initialAverageCost;
            item.expire = this.expire;
            item.dateDue = this.getNewDay(item.productionDate, item.expire);
          } else if (this.type === 2) {
            item.wmsId = element.wmsId;
            item.initialNumber = element.initialNumber;
            item.initialCost = element.initialCost;
            item.initialAverageCost = element.initialAverageCost;
            if (element.serialNoList.length !== 0) {
              item.serialNoList = element.serialNoList.map((val) => {
                return { code: val, remark: "" };
              });
            } else {
              item.serialNoList = new Array(3)
                .fill(new Object({ code: "", remark: "" }))
                .map((val) => {
                  return { ...val };
                });
            }
          }
          if (i < 10) {
            this.tableData.splice(i, 1, { ...item });
          } else {
            this.tableData.push({ ...item });
          }
        }
      }
      if (this.type === 0 || this.type === 2) {
        this.ChangeSelect();
      }
    },
    // 控制input显示 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.serialNoList = row.serialNoList;
      var that = this;
      that.clickRow = row.index;
      that.clickCell = column.index;
      that.tabClickLabel = column.label;
    },
    // 把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },

    // 把每一列的索引放进column
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    // 控制input显示 row 当前行 column 当前列
    serialNoClick(row, column, cell, event) {
      if (this.tableData[this.clickRow].wmsId) {
        var that = this;
        that.serialNoRow = row.index;
        that.serialNoCell = column.index;
        that.serialNoClickLabel = column.label;
      } else {
        this.$message.error("请先选择仓库");
      }
    },
    // 把每一行的索引放进row
    serialNoRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },

    // 把每一列的索引放进column
    serialNollClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    // 失去焦点初始化
    inputBlur(row, event, column) {
      var that = this;
      if (this.type == 1) {
        // 批次的时候判断填的批次号
        if (event.batchNo) {
          if (event.batchNo.length > 32) {
            this.$message.error(
              "批次号格式有误，请输入1-32位数字、字母、特殊字符"
            );
            event.batchNo = null;
            return;
          } else {
            // 选择不为空的时候判断
            this.tableData.forEach((element) => {
              if (element.index === event.index) {
                if (
                  element.wmsId &&
                  element.batchNo &&
                  element.productionDate
                ) {
                  this.deleteRepetition(element, event.index);
                }
              }
            });
          }
        }
      }
      that.clickRow = null;
      that.clickCell = null;
      that.tabClickLabel = "";
      this.serialNoList = [];
    },
    // 更新数组数据及价格
    updateData() {
      this.tableData[this.clickRow].initialNumber = this.tableData[
        this.clickRow
      ].serialNoList.filter((val) => {
        return val.code !== null && val.code !== "";
      }).length;
      const item = this.tableData[this.clickRow];
      item.initialCost =
        Number(item.initialNumber) * Number(item.initialAverageCost);
    },
    addData(index) {
      if (this.tableData.length >= 50)
        return this.$message.error("最多只能插入50行");
      let item = new Object();
      if (this.type === 0) {
        // 普通商品
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.initialAverageCost = 0;
        this.ChangeSelect();
      }
      if (this.type === 1) {
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.batchNo = null;
        item.productionDate = null;
        item.initialAverageCost = 0;
        item.expire = this.expire;
      }
      if (this.type === 2) {
        item.wmsId = null;
        item.initialNumber = null;
        item.initialCost = 0;
        item.initialAverageCost = 0;
        item.serialNoList = [];
        this.ChangeSelect();
        for (let j = 0; j < 3; j++) {
          let element = new Object();
          element.code = null;
          element.remark = "";
          item.serialNoList.push(element);
        }
      }
      this.tableData.splice(index + 1, 0, item);
    },
    deleteData(index) {
      if (this.tableData.length <= 1) {
        this.$message.error("至少保留1行数据");
        return;
      }
      this.tableData.splice(index, 1);
      if (this.type === 0  || this.type === 2) {
        this.ChangeSelect();
      }
    },
    // 选择
    ChangeSelect(value) {
      this.tableColumn[0].data.forEach((val) => {
        return (val.disabled = false);
      });
      const list = this.tableData.filter((val) => {
        return val.wmsId;
      });
      if (list.length) {
        list.forEach((element) => {
          this.tableColumn[0].data.forEach((val) => {
            if (element.wmsId === val.id) {
              val.disabled = true;
            }
          });
        });
      }
    },
    // 批次商品的选择
    ChangeSelectBatch(val, currentIndex) {
      if (val) {
        // 选择不为空的时候判断
        this.tableData.forEach((element) => {
          if (element.index === currentIndex) {
            if (element.wmsId && element.batchNo && element.productionDate) {
              this.deleteRepetition(element, currentIndex);
            }
          }
        });
      }
    },
    deleteRepetition(item, index) {
      // 去除空的
      let list = this.tableData.filter((val) => {
        return val.wmsId && val.batchNo && val.productionDate;
      });
      if (list.length > 1) {
        const arr = list.filter((val) => {
          return val.index !== index;
        });
        for (let i = 0; i < arr.length; i++) {
          const element = arr[i];
          if (
            element.wmsId === item.wmsId &&
            element.batchNo === item.batchNo &&
            element.productionDate === item.productionDate
          ) {
            this.$message.error("表单内第" + (index + 1) + "项为重复数据");
            this.tableData[index].wmsId = null;
            this.tableData[index].batchNo = null;
            this.tableData[index].productionDate = null;
            this.tableData[index].dateDue = null;
          }
        }
      }
    },
    addserialNoRow(index) {
      let element = new Object();
      element.code = null;
      element.remark = null;
      this.tableData[this.clickRow].serialNoList.push(element);
      this.updateData();
    },
    deleteserialNoRow(index) {
      if (this.tableData[this.clickRow].serialNoList.length <= 1) {
        this.$message.error("至少保留1行数据");
        return;
      }
      this.tableData[this.clickRow].serialNoList.splice(index, 1);
      this.updateData();
    },
    changeDate(data) {
      if (data.productionDate === null) data.dateDue = "";
      if (data.productionDate) {
        this.tableData.forEach((element) => {
          if (element.index === data.index) {
            if (element.wmsId && element.batchNo && element.productionDate) {
              this.deleteRepetition(element, data.index);
            }
          }
        });
      } else {
        data.dateDue = "";
      }
      if (data.productionDate) {
        data.dateDue = this.getNewDay(data.productionDate, this.expire);
      } else {
        data.dateDue = "";
      }
    },
    inputChange(data) {
      if (data.initialNumber !== null && data.initialAverageCost !== null) {
        data.initialCost = data.initialNumber * data.initialAverageCost;
      }
    },
    getNewDay(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
    verifyOtherAll(value, scope) {
      let flag = false;
      const list = this.tableData.filter(
        (val) => val.wmsId && val.index !== this.clickRow
      );
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        const serialNoList = element.serialNoList.filter(
          (val) => val.remark === ""
        );
        if (serialNoList.length !== 0) {
          const status = serialNoList.some((item) => item.code === value);
          if (status) {
            scope.row.remark = "与其他列表内序列码重复";
            flag = false;
          } else {
            scope.row.remark = "";
            flag = true;
          }
        }
      }
      return flag;
    },
    findCurrentAll(arr, code, otherId) {
      let results = [],
        len = arr.length,
        pos = 0;
      while (pos < len) {
        pos = arr.indexOf(code, pos);
        if (pos === -1) {
          //未找到就退出循环完成搜索
          break;
        }
        if (otherId !== undefined) {
          results.push(`与序号为${otherId + 1}的数据第${pos + 1}项重复`);
        } else {
          results.push(`与第${pos + 1}项重复`); //找到就存储索引
        }

        pos += 1; //并从下个位置开始搜索
      }
      return results;
    },
    findOtherAll(arr, code) {
      return arr
        .map((item, index) =>
          this.findCurrentAll(
            item.serialNoList.map((item) => item.code),
            code,
            item.index
          )
        )
        .flat();
    },
    handleChange(value, scope) {
      var that = this;
      if (value) {
        if (value.length > 128) {
          this.$message.error(
            "序列码格式有误，请输入1-128位数字、字母、特殊字符"
          );
          e.target.value = null;
          return;
        } else {
          const element = this.tableData[this.clickRow];
          const repetitionRemarks = this.findCurrentAll(
            element.serialNoList
              .filter((item) => item.index !== scope.$index)
              .map((item) => item.code),
            value
          );
          if (repetitionRemarks.length) {
            scope.row.remark = repetitionRemarks.join("、");
            return;
          }
          const otherRemarks = this.findOtherAll(
            this.tableData.filter((item) => item.index !== element.index),
            value
          );
          if (otherRemarks.length) {
            scope.row.remark = otherRemarks.join("、");
            return;
          }
          if (!repetitionRemarks.length && !otherRemarks.length) {
            scope.row.remark = "";
            search({ code: value }).then((res) => {
              if (res.code === 1) {
                this.updateData();
                scope.row.remark = "";
                that.serialNoRow = null;
                that.serialNoCell = null;
                that.serialNoClickLabel = "";
              } else {
                scope.row.remark = res.msg;
              }
            });
          }
        }
      } else {
        this.updateData();
        this.tableData[that.clickRow].serialNoList[that.serialNoRow].remark =
          "";
        that.serialNoRow = null;
        that.serialNoCell = null;
        that.serialNoClickLabel = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-table__body tr:hover > td {
  background-color: #ebf3fd !important;
  // color: dodgerblue;  /* 设置文字颜色，可以选择不设置 */
}
/deep/ .el-table__body tr.current-row > td {
  background-color: #ebf3fd !important;
  // color: coral; /* 设置文字颜色，可以选择不设置 */
}
// ::v-deep .el-table .el-table__body tr td:hover{
//   background-color: #d4b021 !important;
// }
// /deep/ .editColumn{
//     background-color: #fefbd0;
// }
// /deep/ .forbidColumn{
//     background-color: #EBEEF5;
// }
</style>