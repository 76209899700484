
// 采购单
export class Pustorage {
    constructor(producName,productId,batchNo,productionDate,expire) {
        this.producName = producName
        this.productId = productId
        this.batchNo = batchNo
        this.productionDate = productionDate
        this.expire = expire
    }
}
// 入库单表格商品
export class PustorageTableGoods {
    constructor(producName,code,regCode,batchNo,productionDate,expire,dateDue,unit,unitId,unitConversion,quantity,price,money,remark) {
        this.producName = producName
        this.code = code
        this.regCode = regCode
        this.batchNo = batchNo
        this.productionDate = productionDate
        this.expire = expire
        this.dateDue = dateDue
        this.unit = unit
        this.unitId = unitId
        this.unitConversion = unitConversion
        this.quantity = quantity
        this.price = price
        this.money = money
        this.remark = remark;
        
    }
}
// 提交-普通商品
export class commonGoods {
    constructor(productId,num,unitId,price,remark){
        this.productId = productId
        this.num = num
        this.unitId = unitId
        this.price = price
        this.remark  = remark
    }
}
// 提交-批次商品
export class batchGoods {
    constructor(productId,batchNo,productionDate,expire,expireDate,num,unitId,price){
        this.productId = productId
        this.batchNo = batchNo
        this.productionDate = productionDate
        this.expire = expire
        this.expireDate = expireDate
        this.unitId = unitId
        this.num = num
        this.price = price
    }
}
// 提交-序列码商品
export class SerialGoods {
    constructor(productId,serialNoList,num,unitId,price){
        this.productId = productId
        this.serialNoList = serialNoList
        this.unitId = unitId
        this.num = num
        this.price = price
    }
}
// 提交-批次序列码商品
export class BatchandSerialGoods{
    constructor(productId,batchNo,productionDate,expire,expireDate,serialNoList,num,unitId,price){
        this.productId = productId
        this.batchNo = batchNo
        this.productionDate = productionDate
        this.expire = expire
        this.expireDate = expireDate
        this.serialNoList = serialNoList
        this.unitId = unitId
        this.num = num
        this.price = price
    }
}
// 退货单表格商品（带所有字段）
export class SalesreturnTableGoods{
    constructor(producName,code,producTypeName,batchNo,productionDate,expire,dateDue,unit,unitId,unitConversion,inventory,backNumber,quantity,price,money) {
        this.producName = producName
        this.code = code
        this.batchNo = batchNo
        this.producTypeName = producTypeName
        this.inventory = inventory
        this.backNumber = backNumber
        this.productionDate = productionDate
        this.expire = expire
        this.dateDue = dateDue
        this.unit = unit
        this.unitId = unitId
        this.unitConversion = unitConversion
        this.quantity = quantity
        this.price = price
        this.money = money
    }
}
// 退货单普通商品
export class SalesreturnGoods{
    constructor(id,producName,productId,code,producTypeName,unit,unitId,unitConversion,inventory,backNumber,quantity,price,money) {
        this.id = id
        this.productId = productId
        this.producName = producName
        this.code = code
        this.producTypeName = producTypeName
        this.unit = unit
        this.unitId = unitId
        // this.unitList = unitList
        this.unitConversion = unitConversion
        this.inventory = inventory
        this.backNumber = backNumber
        this.quantity = quantity
        this.price = price
        this.money = money
        this.isBatch = 0
        this.isSerial = 0
    }
}
// 退货单批次商品
export class SalesreturnBatchGoods{
    constructor(id,producName,productId,code,producTypeName,batchNo,productionDate,expire,dateDue,unit,unitId,unitList,unitConversion,inventory,backNumber,quantity,price,money) {
        this.id = id
        this.productId = productId
        this.producName = producName
        this.code = code
        this.producTypeName = producTypeName
        this.batchNo = batchNo
        this.productionDate = productionDate
        this.expire = expire
        this.dateDue = dateDue
        this.unit = unit
        this.unitId = unitId
        this.unitList = unitList
        this.unitConversion = unitConversion
        this.inventory = inventory
        this.backNumber = backNumber
        this.quantity = quantity
        this.price = price
        this.money = money
        this.isBatch = 1
        this.isSerial = 0
    }
}
// 退货单序列码商品
export class SalesreturnSerialGoods{
    constructor(id,producName,productId,code,producTypeName,unit,unitId,unitList,unitConversion,inventory,backNumber,serialNoList,quantity,price,money) {
        this.id = id
        this.productId = productId
        this.producName = producName
        this.code = code
        this.producTypeName = producTypeName
        this.unit = unit
        this.unitId = unitId
        this.unitList = unitList
        this.unitConversion = unitConversion
        this.inventory = inventory
        this.backNumber = backNumber
        this.serialNoList = serialNoList
        this.quantity = quantity
        this.price = price
        this.money = money
        this.isBatch = 0
        this.isSerial = 1
    }
}