<template>
    <el-card :style="{marginBottom: '20px'}">
        <div slot="header" class="clearfix">
            <div class="title">
                <span class="tipicon"></span>
                <span>{{title}}</span>
            </div>
        </div>
         <slot name='content'></slot>
    </el-card>
</template>
<script>
export default {
    name:'miaCard',
    props:{
        title:{
            type: String,
            default:''
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-card__header{
    border-bottom: 0px ;
    .title{
        display: inline-flex;
        align-items: center;
        .tipicon{
            display: inline-block;
            width: 4px;
            height: 18px;
            background: #1D85EE;
            border-radius: 2px;
            margin-right: 12px;
            margin-left: 4px;
        }
    }
}
</style>