import Cookies from 'js-cookie'
import router from '@/router'
import store from '@/store'

const TokenKey = 'danduoduo_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

// 时间补0
export function addZero(s) {
  return s < 10 ? "0" + s : s;
}
// 价格显示
export function setInterval(num){
  var len = 3 
 num = parseInt(num, 10) + 1//转数据类型，以十进制自增
 num = num.toString()//转为字符串
 while (num.length < len) {//当字符串长度小于设定长度时，在前面加0
     num = "0" + num
 }
 //如果字符串长度超过设定长度只做自增处理。
 return num
}
// 获取当前时间（时分秒）
export function getDate() {
  var now = new Date();
  var year = now.getFullYear(); //得到年份
  var month =
    now.getMonth() + 1 >= 10
      ? now.getMonth() + 1
      : "0" + (now.getMonth() + 1); //得到月份
  var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
  var hour = addZero(now.getHours());
  var minute = addZero(now.getMinutes());
  var second = addZero(now.getSeconds());
  return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
}
// 设置默认时间
export function datatimestatus() {
  return {
    disabledDate(time) {
      let timeTmp = time.getTime();
      let startTmp = new Date(
        sessionStorage.getItem("createDate")
      ).getTime() - 3600 * 1000 * 24;
      let endTmp = Date.now();
      return timeTmp < startTmp || timeTmp > endTmp;
    },
  };
}
// 设置单位换算
export function backUnit(List,currentID,){
  const unitlist = List.filter(item => item.id === currentID) //当前设置的对应单位
  const baselist =  List.filter(item => item.isInit=='0') // 基本单位
  if(unitlist[0].isInit != '0') {
    // 说明对应单位为辅助单位
    return `1${unitlist[0].name} = ${unitlist[0].value}${baselist[0].name}`
  } else {
    return '-'
  }
}
// 设置 默认（月）时间
export function GetMouthDate(){
  var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month =
      now.getMonth() + 1 >= 10
        ? now.getMonth() + 1
        : "0" + (now.getMonth() + 1); //得到月份
    var date = now.getDate() >= 10 ? now.getDate() : "0" + now.getDate(); //得到日期
    var defaultDate = [`${year}-${month}-01`, `${year}-${month}-${date}`];
    return defaultDate;
}
export function clearLoginInfo (){
  sessionStorage.clear()
  store.commit('resetStore')
  router.push({path: '/'})
  router.options.isAddDynamicMenuRoutes = false
  window.location.reload()
} 