<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  created(){
    //禁止浏览器回退,只能回退一次
    window.onload=function () {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', function () {
        history.pushState(null, null, document.URL);
      });
    },
    this.$router.beforeEach((to, from, next) => {
    this.routeHandle(to)
    next();
    });
  },
  computed:{
      ...mapState("common", { 
      secondMenuList: (state) => state.secondMenuList,
      menuList: (state) => state.menuList,
      mainTabs: (state) => state.mainTabs,
      AllmenuList:(state) => state.AllmenuList,
      menuActiveName:(state) => state.menuActiveName,
      mainTabsActiveName:(state) => state.mainTabsActiveName,
      }),
    },
  methods:{
    ...mapMutations("common", ["updateMainTabs",'updateMainTabsActiveName', 'updateMenuActiveName', 'updataSecondMenuList','updataActiveId']),
        routeHandle(route){
          if(route.name === 'home') {
            this.updateMainTabsActiveName('')
            this.updataActiveId(null)
          }
            let newMainTabs = this.mainTabs
            if(route.meta.isTab){
                var tab = this.mainTabs.filter(item => item.urlName === route.path.substr(1))[0]
                if(!tab){
                    let menu = {
                        title: route.meta.title,
                        urlName: route.path.substr(1),
                        params: route.params,
                        query: route.query,
                        parentName: route.meta.parentName,
                        id:route.meta.id
                    }
                    newMainTabs = newMainTabs.concat(menu)
                }
                this.updateMainTabs(newMainTabs)
                const data = this.AllmenuList.filter(val => { return val.id === route.meta.id })
                if(data.length !== 0) {
                  this.updateMenuActiveName(data[0].id + '')
                  this.updateMainTabsActiveName(route.path.substr(1))
                  sessionStorage.setItem('cacheData', route.path.substr(1))
                  const ids = this.backRouterInfo(this.menuList, data[0].parent).parent
                  const list = this.menuList.filter(val => { return val.id ===  ids })
                  if(list.length !== 0) {
                    this.updataActiveId(list[0].id)
                    this.updataSecondMenuList(list[0].children)
                  }
                } else {
                  this.updateMenuActiveName('')
                  this.updataSecondMenuList([])
                  this.updataActiveId(null)
                  this.updateMainTabsActiveName(route.path.substr(1))
                  this.updataSecondMenuList([])
                }
            }
        },
        backRouterInfo(list, id) {
          let res = list.find(item => item.id == id)
          if (res) {
            return res
          } else {
            for (let i=0; i<list.length; i++) {
              if (list[i].children instanceof Array && list[i].children.length > 0) {
                res = this.backRouterInfo(list[i].children, id)
                if(res){
                  return res
                }
              }
            }
            return null
          }
        }
  }
}
</script>
<style>
#app {
  font-family: Noto Sans PingFang;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
