import request from '@/until/request.js'
const prefixName = 'base/user-custom'
/** 获取用户列表 */
export function getUserList(data) {
    return request({
      url: prefixName,
      method: 'get',
      params: { ...data }
    })
  }
  export function search(searchValue = {}, pageNum, pageSize) {
    return request({
      url: prefixName,
      method: 'get',
      params: { ...searchValue, pageNum, pageSize }
    })
  }
  
  export function check(name) {
    return request({
      url: prefixName + '/name/' + name,
      method: 'get'
    })
  }
  
  export function getById(id) {
    return request({
      url: prefixName + '/' + id,
      method: 'get'
    })
  }
  
  export function save(result) {
    return request({
      url: prefixName,
      method: 'post',
      data: result
    })
  }
  
  export function update(result) {
    return request({
      url: prefixName,
      method: 'put',
      data: result
    })
  }
  
  export function deleteById(id) {
    return request({
      url: prefixName + '/' + id,
      method: 'delete'
    })
  }
  export function changeEnAble(id, status) {
    const statusName = status === 0 ? 'disabled' : 'enable'
    return request({
      url: prefixName + '/status/' + statusName,
      method: 'put',
      data: { id: id }
    })
  }
  export function checkPhone(phone) {
    // /base/user/phone/{phone}/officeId/{officeId}
    return request({
      url: prefixName + '/phone/' + phone,
      method: 'get'
    })
  }
  export function resetPassWord(result){
    return request({
      url: prefixName + '/reset/password',
      method: 'put',
      data: result
    })
  }  
  export function resetPhone(result){
    return request({
      url: prefixName + '/resetPhone',
      method: 'put',
      data: result
    })
  }
  export function unbindPhone(id){
    return request({
      url: prefixName + '/unbound',
      method: 'put',
      data: { id: id }
    })
  }  
  //开账状态
  export function getConfigBill(query){
    return request({
      url: '/yny/global/getConfigBill',
      method: 'get',
      params:query
    })
  }
  //设置开账  
  export function updConfigBill(query){
    return request({
      url: '/yny/company/updConfigBill',
      method: 'PUT',
      data:query
    })
  }  