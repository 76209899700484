<template>
  <div style="position: relative">
    <el-table
      :data="tableList"
      border
      height="58vh"
      size="mini"
      show-summary
      :summary-method="getSummaries"
      style="width: 100%"
      @cell-click="tabClick"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName"
    >
      <el-table-column align="center" label="序号" width="50">
        <template scope="scope">
          <span>{{ scope.$index + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column align="center" v-if="!examine" width="100" label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            :disabled="examine || tableList.length > 50"
            @click="addData(scope.$index)"
            >插入</el-button
          >
          <el-button
            type="text"
            :disabled="examine || tableList.length <= 1"
            @click="deleteData(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        v-for="(item, index) in tableColumn"
        :class-name="`${item.prop}_label ${
          item.edit ? 'editColumn' : 'forbidColumn'
        }`"
        :key="index"
        :label="item.label"
        :prop="item.prop"
      >
        <template slot="header" slot-scope="scope">
          <span v-if="item.required" style="color: red; margin-right: 5px"
            >*</span
          ><span>{{ item.label }}</span>
        </template>
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRow &&
              scope.column.index === clickCell &&
              item.edit
            "
          >
            <el-row type="flex">
              <el-col :span="18"
                ><el-input
                  v-if="item.type === 'input' && item.prop === 'batchNo'"
                  ref="editInput"
                  v-model="scope.row[item.prop]"
                  size="mini"
                  style="width: 100%"
                  onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
                  @blur="inputBlur($event, scope.row)"
              /></el-col>
              <el-col :span="6"
                ><div
                  v-if="item.prop === 'batchNo'"
                  class="buttonSeach"
                  @click="SelectBatchNo(scope.row)"
                >
                  <i class="el-icon-zoom-in"></i></div
              ></el-col>
            </el-row>
            <el-input
              v-if="item.type === 'input' && item.prop !== 'batchNo'"
              ref="editInput"
              v-model="scope.row[item.prop]"
              size="mini"
              onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
              @blur="inputBlur($event, scope.row)"
            />
            <el-select
              v-if="item.type === 'select'"
              v-model="scope.row[item.prop]"
              placeholder="请选择"
              size="mini"
              @change="
                (val) => {
                  ChangeSelect(val, scope.row);
                }
              "
            >
              <el-option
                v-for="(item, index) in scope.row.unitList"
                :key="index"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-row type="flex">
              <el-col :span="item.type === 'number' ? 24 : 20"
                ><el-input-number
                  v-if="item.type === 'number' || item.type === 'price'"
                  v-model="scope.row[item.prop]"
                  controls-position="right"
                  :min="item.min"
                  :max="item.max"
                  size="mini"
                  @blur="inputBlur"
                  @change="inputChange($event, scope.row)"
                  :precision="item.precision"
                ></el-input-number
              ></el-col>
              <el-col :span="4" v-if="item.type === 'price'"
                ><div class="buttonSeach" @click="selectPrice(scope.row)">
                  <i class="el-icon-zoom-in"></i></div
              ></el-col>
            </el-row>
            <el-date-picker
              v-if="item.type === 'date'"
              v-model="scope.row[item.prop]"
              type="date"
              size="mini"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              placeholder="选择日期"
              @change="() => changeDate(scope.row)"
            >
            </el-date-picker>
          </div>
          <div
            v-else
            :style="
              item.type === 'price'
                ? { 'text-align': 'right' }
                : { 'text-align': 'left' }
            "
          >
            <span v-if="item.type === 'price'">{{
              scope.row[item.prop] | backPrice
            }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 入库单 -->
    <div v-if="!examine" style="width: 100%; margin-top: 20px">
      <el-row>
        <el-col :span="2" style="color: #666"> 备注： </el-col>
        <el-col :span="22">
          <el-input type="textarea" v-model="pustorageForm.remark"></el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 20px">
        <el-form size="mini" :inline="true" :model="pustorageForm">
          <el-form-item label="优惠金额：">
            <el-input-number
              size="mini"
              v-model="pustorageForm.preferentialPrice"
              controls-position="right"
              :min="0.0"
              :max="allPrice"
              :precision="2"
              @change="ChangePrice"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="应付金额："
            style="color: #ff8c42; font-weight: bold"
          >
            ￥
            <span v-if="pustorageForm.handlePrice">
              {{ pustorageForm.handlePrice | backPrice }}
            </span>
            <span v-else>0.00</span>
            元
          </el-form-item>
          <!-- <el-form-item v-else label="应付金额："> -->
          <!-- <span v-if="pustorageForm.handlePrice">
              {{ pustorageForm.handlePrice | backPrice }}
            </span>
            <span v-else>0.00</span> -->
          <!-- <el-input-number
              size="mini"
              v-model="pustorageForm.handlePrice"
              controls-position="right"
              :min="0.0"
              :max="allPrice"
              :precision="2"
              @change="ChangeHandlePrice"
            ></el-input-number>
          </el-form-item> -->

          <!-- <el-form-item label="付款金额：">
            <span>{{ pustorageForm.thisPayment | backPrice }}</span> -->
          <!-- <el-input-number
              size="mini"
              v-model="pustorageForm.thisPayment"
              controls-position="right"
              :min="0.0"
              :max="pustorageForm.handlePrice"
              @change="ChangeArrearage"
              :precision="2"
            ></el-input-number> -->
          <!-- </el-form-item> -->
          <!-- <el-form-item label="付款账户：">
            <el-select
              v-model="pustorageForm.accountId"
              placeholder="请选择"
              clearable
              filterable
              :disabled="
                pustorageForm.thisPayment === 0 ||
                pustorageForm.thisPayment === undefined
              "
              @clear="clearAccountId"
            >
              <el-option
                v-for="item in AccountList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->

          <!-- <el-form-item label="本次欠款：">
            <span v-if="arrearage">
              {{ arrearage | backPrice }}
            </span>
            <span v-else>0.00</span>
          </el-form-item> -->
          <el-form-item style="text-align: right; width: 45%">
            <el-button type="warning" plain size="medium" @click="saveCaogao">
              保存草稿
            </el-button>
            <el-button type="danger" size="medium" @click="openAccountDialog">
              结算
            </el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <!-- 查看状态 -->
    <div v-else style="width: 100%; margin-top: 20px">
      <!-- <el-row type="flex" justify="start">
        <el-col :span="3"> 制单人：{{ examineForm.createUserName }} </el-col>
        <el-col :span="6"> 最后修改时间：{{ examineForm.updateTime }} </el-col>
        <el-col :span="4">
          单据状态：{{ examineForm.status == "0" ? "已完成" : "草稿" }}
        </el-col>
      </el-row> -->
      <div style="margin: 20px 0 10px 0">付款信息</div>
      <el-row>
        <el-col :span="6">
          合计金额：￥{{ (examineForm.price / 100).toFixed(2) }}
        </el-col>
        <el-col :span="6">
          整单优惠：￥{{ examineForm.preferentialPrice.toFixed(2) }}
        </el-col>
        <el-col :span="6">
          应付金额：<span style="color: red">
            ￥{{ examineForm.handlePrice.toFixed(2) }}</span
          >
        </el-col>
        <el-col :span="6">
          剩余欠款：<span style="color: red">
            ￥{{
              (examineForm.handlePrice - examineForm.prepaidPrice).toFixed(2)
            }}</span
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          已收金额：<span style="color: blue"
            >￥{{ examineForm.prepaidPrice.toFixed(2) }}</span
          >({{
            examineForm.accountList
              .map((v) => {
                return v.accountName + ":" + (v.price / 100).toFixed(2);
              })
              .join(";")
          }})
        </el-col>
      </el-row>
    </div>
    <!-- 选择商品 -->
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods @close="Visible = false" @SelectGoods="AddGoods" />
      </div>
    </miaDialog>
    <!-- 选择批次商品 -->
    <miaDialog width="70%" ref="SelectBatchGoods" v-if="BatchGoodsVisible">
      <div slot="content">
        <SelectBatchGoods
          :data="currentBatchData"
          :miaWmsId="wmsId"
          @close="BatchGoodsVisible = false"
          @SelectBatchGoods="AddBatchGoods"
        />
      </div>
    </miaDialog>
    <!-- 选择序列码商品 -->
    <miaDialog width="70%" ref="SelectSerialGoods" v-if="SerialGoodsVisible">
      <div slot="content">
        <SelectSerialGoods
          :data="currentBatchData"
          :miaWmsId="wmsId"
          @close="SerialGoodsVisible = false"
          @SelectSerialGoods="AddSerialGoods"
        />
      </div>
    </miaDialog>
    <!-- 选择单价 -->
    <miaDialog width="70%" ref="SelectPrice" v-if="SelectPriceVisible">
      <div slot="content">
        <SelectPrice
          :data="PriceData"
          @close="SelectPriceVisible = false"
          @SelectPrice="PriceChange"
        />
      </div>
    </miaDialog>
    <!-- 查看序列码 -->
    <miaDialog width="70%" ref="examineSerialGoods" v-if="examineSerialGoods">
      <div slot="content">
        <el-table
          :data="examineSerialGoodsList"
          highlight-current-row
          border
          height="55vh"
        >
          <el-table-column prop="code" label="序列码"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
        </el-table>
      </div>
    </miaDialog>
    <!-- 多个付款账户 -->
    <miaDialog width="35%" ref="multiAccount" v-if="multiAccountVisible">
      <div slot="content">
        <span v-if="pustorageForm.handlePrice">应付金额：</span
        ><span style="color: #ff8c42; font-weight: bold"
          >￥{{ pustorageForm.handlePrice | backPrice }}</span
        >
        <el-table :data="AccountList" border>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column property="name" label="账户"></el-table-column>
          <el-table-column property="address" label="付款金额">
            <template slot-scope="scope">
              <el-input-number
                size="mini"
                v-model="scope.row.price"
                :controls="false"
                :min="0"
                :max="99999"
                :precision="2"
                placeholder="请录入"
              ></el-input-number>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 20px">
          <span>已付金额：</span>
          <span style="color: #ff8c42; font-weight: bold"
            >￥{{ pustorageForm.thisPayment | backPrice }}</span
          >
        </div>
        <div>
          <span>本单欠款：</span
          ><span style="color: #ff8c42; font-weight: bold"
            >￥{{
              (pustorageForm.handlePrice - pustorageForm.thisPayment)
                | backPrice
            }}</span
          >
        </div>
        <div style="text-align: right; margin-top: 20px">
          <el-button @click="closeAccountDialog">取消</el-button>
          <el-button type="primary" @click="saveAccount">保存</el-button>
        </div>
      </div>
    </miaDialog>
    <div
      class="mask"
      v-if="!wmsId"
      @click="$message.error('请选择入库仓库')"
    ></div>
  </div>
</template>
<script>
import SelectGoods from "./SelectGoods.vue";
import SelectBatchGoods from "./selectBatchGoods.vue";
import SelectSerialGoods from "./selectSerialGoods.vue";
import SelectPrice from "./selectPrice.vue";
import { PustorageTableGoods } from "@/until/statement.js";
import { GetAccountList } from "@/api/account";
import { mapState } from "vuex";
export default {
  name: "pustorageTable",
  components: {
    SelectGoods,
    SelectBatchGoods,
    SelectSerialGoods,
    SelectPrice,
  },
  computed: {
    ...mapState("user", ["name"]),
  },
  props: {
    tableColumn: {
      type: Array,
      default: () => [],
    },
    wmsId: {
      default: () => "",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    examine: {
      type: Boolean,
      default: () => false,
    },
    examineForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      clickRow: null, // 当前点击的行
      clickCell: null, // 当前点击的列
      tabClickLabel: "", // 当前点击的列名
      mask: true,
      // 当前展示的列表
      tableList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pustorageForm: {
        purchasePrice: 0,
        preferentialPrice: 0,
        handlePrice: 0,
        thisPayment: 0,
        accountId: "",
        accountList: [],
      },
      // 欠款金额
      arrearage: 0,
      Visible: false,
      currentPointIndex: null,
      AccountList: [],
      BatchGoodsVisible: false,
      currentBatchData: null,
      SerialGoodsVisible: false,
      SelectPriceVisible: false,
      examineSerialGoods: false,
      examineSerialGoodsList: [],
      PriceData: {},
      allPrice: null,
      multiAccountVisible: false,
      config: {},
    };
  },
  watch: {
    tableData(val) {
      this.tableList = val;
    },
    tableList(val) {
      this.handlePriceCount();
    },
    wmsId(val) {
      this.wmsId = val;
    },
    examine(val) {
      this.examine = val;
    },
    examineForm(val) {
      this.examineForm = val;
      this.arrearage = (
        (val.handlePrice * 100 - val.prepaidPrice * 100) /
        100
      ).toFixed(2);
    },
  },
  filters: {
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
  },
  created() {
    this.tableList = JSON.parse(JSON.stringify(this.tableData));
    this.GetAccount();
  },
  mounted() {
    this.config = JSON.parse(sessionStorage.getItem("config"));
    // 修改的时候把金额赋值
    if (!this.examine && this.examineForm) {
      Object.keys(this.pustorageForm).forEach((key) => {
        if (this.examineForm[key] && this.examineForm[key] !== undefined) {
          this.pustorageForm[key] = this.examineForm[key];
        } else {
          this.pustorageForm[key] = "";
        }
      });
    }
  },
  methods: {
    // 打开多账户付款
    openAccountDialog() {
      this.multiAccountVisible = true;
      this.$nextTick(() => {
        this.$refs.multiAccount.init("多账户付款");
      });
    },

    // 关闭多账户窗口
    closeAccountDialog() {
      this.$refs.multiAccount.close();
    },
    saveAccount() {
      let total = 0;
      this.pustorageForm.accountList = this.AccountList.filter(
        (a) => a.price != undefined
      ).map((a) => {
        total += a.price;
        return { accountId: a.id, price: a.price * 100 };
      });

      if (this.pustorageForm.handlePrice < total) {
        return this.$message.error("付款金额不能大于应付金额");
      }
      this.pustorageForm.thisPayment = total;
      this.ChangeArrearage(total);
      this.closeAccountDialog();
      this.$emit("save");
    },
    // 控制input显示 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      if (!this.examine) {
        if (this.wmsId) {
          this.currentPointIndex = row.index;
          if (column.property === "producName") {
            this.Visible = true;
            this.$nextTick(() => {
              this.$refs.SelectGoods.init("选择商品");
            });
          } else {
            if (row.producName) {
              // 判断是否为批次商品
              if (!row.isBatch && !row.isSerial) {
                // 普通商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                )
                  return;
              }
              if (row.isSerial && row.isBatch) {
                // 批次及序列码商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                ) {
                  this.openEdit(row.index, column.index, column.label);
                }
                if (column.property === "quantity") {
                  if (row.batchNo && row.productionDate) {
                    this.SerialGoodsVisible = true;
                    this.currentBatchData =
                      this.tableList[this.currentPointIndex];
                    this.$nextTick(() => {
                      this.$refs.SelectSerialGoods.init("序列码录入");
                    });
                    this.openEdit(null, null, null);
                    return;
                  } else {
                    this.openEdit(null, null, null);
                    return;
                  }
                }
              }
              if (row.isSerial && !row.isBatch) {
                // 序列码商品
                if (
                  column.property === "batchNo" ||
                  column.property === "productionDate"
                )
                  return;
                if (column.property === "quantity") {
                  this.SerialGoodsVisible = true;
                  this.currentBatchData =
                    this.tableList[this.currentPointIndex];
                  this.$nextTick(() => {
                    this.$refs.SelectSerialGoods.init("序列码录入");
                  });
                  return;
                }
              }
              this.openEdit(row.index, column.index, column.label);
            } else {
              return;
            }
          }
        } else {
          this.$message.error("请先选择您要入库的仓库");
        }
      } else {
        if (row.serialNoList && column.property === "quantity") {
          this.examineSerialGoods = true;
          this.examineSerialGoodsList = [];
          row.serialNoList.forEach((val) => {
            this.examineSerialGoodsList.push({ code: val, remark: "" });
          });
          this.$nextTick(() => {
            this.$refs.examineSerialGoods.init("查看序列码列表");
          });
        }
      }
    },
    openEdit(rowIndex, columnIndex, label) {
      this.clickRow = rowIndex;
      this.clickCell = columnIndex;
      this.tabClickLabel = label;
    },
    // 把每一行的索引放进row
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.isBatch) {
        return "BatchGoods";
      }
      if (row.isSerial) {
        return "SerialGoods";
      }
      if (row.isBatch === 0 && row.isSerial === 0) {
        return "GeneralGoods";
      }
      return "";
    },
    // 把每一列的索引放进column
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex;
    },
    saveCaogao() {
      this.$emit("saveCaogao");
    },
    // 失去焦点初始化
    inputBlur(row, data) {
      console.log(data);
      // if (data.batchNo) {
      //   if (data.batchNo.length > 32 || data.batchNo.length < 6) {
      //     data.batchNo = "";
      //     this.$message.error("批次号的长度在6-32位之间");
      //   } else {
      //     this.openEdit(null, null, null);
      //   }
      // } else {
      //   this.openEdit(null, null, null);
      // }
    },
    ChangeHandlePrice() {
      // 修改整单金额
    },
    // 添加选中的商品至表格
    AddGoods(data) {
      this.openEdit(null, null, null);
      this.Visible = false;
      if (data.length !== 0) {
        let list = [];
        data.forEach((v, index) => {
          const obj = {
            ...new PustorageTableGoods(
              v.name,
              v.code,
              null,
              v.batchNo,
              null,
              null,
              null,
              v.unit,
              v.id,
              "",
              1,
              v.unitList.filter((i) => i.isInit == "0")[0].newPurchasePrice /
                100,
              null,
              v.regCode
            ),
          };
          obj.producTypeName = "普通";
          if (v.isBatch) {
            obj.producTypeName = "批次";
            obj.expire = v.batch.expire;
          }
          if (v.isSerial) {
            obj.serialNoList = [];
            obj.quantity = 0;
            obj.producTypeName = "序列码";
          }
          if (v.isBatch && v.isSerial) {
            obj.producTypeName = "批次序列码";
            obj.quantity = 0;
            obj.serialNoList = [];
          }
          obj.index = index;
          obj.productId = v.id;
          obj.id = v.id;
          obj.isBatch = v.isBatch;
          obj.isSerial = v.isSerial;
          obj.isMultiUnit = v.isMultiUnit;
          obj.unitList = v.unitList;
          obj.money = obj.quantity * obj.price;
          list.push(obj);
        });

        this.tableData.splice(this.currentPointIndex, 1, ...list);
      }
    },
    // 选择生产日期
    changeDate(data) {
      if (data.productionDate) {
        data.dateDue = this.getNewDay(data.productionDate, data.expire);
      } else {
        data.dateDue = "";
      }
    },
    // 计算到期日期
    getNewDay(dateTemp, days) {
      var dateTemp = dateTemp.split("-");
      var nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
      var millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
      var rDate = new Date(millSeconds);
      var year = rDate.getFullYear();
      var month = rDate.getMonth() + 1;
      if (month < 10) month = "0" + month;
      var date = rDate.getDate();
      if (date < 10) date = "0" + date;
      return year + "-" + month + "-" + date;
    },
    ChangeSelect(value, data) {
      const item = data.unitList.filter((val) => {
        return val.name == value;
      });
      if (item[0].isInit === 1) {
        // 为辅助单位
        const arr = data.unitList.filter((val) => {
          return val.isInit === 0;
        })[0];
        data.unitConversion = `${arr.value}${item[0].name} = ${item[0].value}${arr.name}`;
      } else {
        data.unitConversion = item[0].value;
      }
      data.unitId = item[0].id;
      data.price = Number(item[0].purchasePrice);
      data.money = Number(data.quantity) * Number(item[0].purchasePrice);
      this.handlePriceCount();
    },
    inputChange(value, data) {
      data.money = Number(data.quantity) * Number(data.price);
      this.handlePriceCount();
    },
    // 合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //calcProp 为需要计算的列的prop值的集合
        if (column.property === "quantity") {
          const values = data.map((item) => item["quantity"]);
          sums[index] = values.reduce((prev, curr) => {
            if (curr) {
              return prev + curr;
            } else {
              return prev;
            }
          });
        }
        if (column.property === "money") {
          const values = data.map((item) => Number(item[column.property]));
          let num = 0;
          values.forEach((v) => {
            if (v) {
              return (num += parseFloat(v));
            } else {
              return (num += 0);
            }
          });
          sums[index] = this.backPrice((num.toFixed(3) * 1000) / 1000);
        }
      });
      return sums;
    },
    GetAccount() {
      GetAccountList({}).then((res) => {
        if (res.code === 1) {
          this.AccountList = res.data.records;
        } else {
          this.AccountList = [];
        }
      });
    },
    // 计算合计金额
    handlePriceCount() {
      let num = 0;
      this.tableList.forEach((v) => {
        if (v.money) {
          num += parseFloat(v.money);
        } else {
          num += 0;
        }
      });
      num = (num.toFixed(3) * 1000) / 1000;
      this.pustorageForm.purchasePrice = num;
      this.allPrice = num;
      this.pustorageForm.handlePrice =
        Number(this.pustorageForm.purchasePrice) -
        Number(this.pustorageForm.preferentialPrice);
      // 改变应付金额
      this.ChangePrice(this.pustorageForm.preferentialPrice);
      // 欠款金额
      this.ChangeArrearage(this.pustorageForm.thisPayment);
    },
    // 改变优惠金额
    ChangePrice(value) {
      if (value === undefined) return;
      // 改变优惠金额的时候改变应付金额
      this.pustorageForm.handlePrice =
        (Number(this.pustorageForm.purchasePrice) * 100 - Number(value) * 100) /
        100;
      // 大于的时候
      // if (this.pustorageForm.thisPayment > this.pustorageForm.handlePrice) {
      //   this.pustorageForm.thisPayment = this.pustorageForm.handlePrice;
      // }
      // 欠款金额
      this.ChangeArrearage(this.pustorageForm.thisPayment);
    },
    // 计算欠款金额
    ChangeArrearage(value) {
      if (value === undefined) {
        this.pustorageForm.accountId = "";
        this.arrearage = Number(this.pustorageForm.handlePrice);
        return;
      }
      if (this.pustorageForm.thisPayment === 0)
        this.pustorageForm.accountId = "";
      // 欠款金额 =  应付金额 - 本次付款金额
      this.arrearage = (
        (Number(this.pustorageForm.handlePrice) * 100 -
          Number(this.pustorageForm.thisPayment) * 100) /
        100
      ).toFixed(2);
    },
    // 插入行
    addData(index) {
      if (this.tableData.length >= 50)
        return this.$message.error("最多只能插入50行");
      const item = {
        ...new PustorageTableGoods(),
      };
      this.tableData.splice(index + 1, 0, item);
    },
    // 删除行
    deleteData(index) {
      if (this.tableData.length <= 1) {
        this.$message.error("至少保留1行数据");
        return;
      }
      this.tableData.splice(index, 1);
    },
    // 打开选择批次商品的弹框
    SelectBatchNo(data) {
      this.currentBatchData = data;
      this.BatchGoodsVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectBatchGoods.init("选择批次商品");
      });
    },
    // 添加批次商品
    AddBatchGoods(list, data) {
      this.BatchGoodsVisible = false;
      // list 选择添加的批次号数据
      // data 当前的批次商品信息 如果list位多个 将重复添加商品及批次号
      if (list.length === 1) {
        // 一个数据
        this.tableList[data.index].batchNo = list[0].batchNo;
        this.tableList[data.index].dateDue = list[0].dateDue;
        this.tableList[data.index].expire = list[0].expire;
        this.tableList[data.index].productionDate = list[0].manufactureDate;
      } else {
        const item = this.tableList[this.currentPointIndex];
        let pointTabel = [];
        list.forEach((val) => {
          const obj = { ...item };
          Object.keys(val).forEach((key) => {
            if (val[key]) {
              obj[key] = val[key];
            }
          });
          obj.productionDate = val.manufactureDate;
          pointTabel.push(obj);
        });
        this.tableData.splice(this.currentPointIndex, 1, ...pointTabel);
      }
      this.openEdit(null, null, null);
    },
    // 添加序列码商品
    AddSerialGoods(list) {
      const item = this.tableList[this.currentPointIndex];
      item.serialNoList = list;
      item.quantity = list.length;
      item.money = Number(item.quantity) * Number(item.price);
      this.handlePriceCount();
      this.SerialGoodsVisible = false;
      this.currentPointIndex = null;
      this.currentBatchData = null;
    },
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    selectPrice(data) {
      this.PriceData = data;
      this.SelectPriceVisible = true;
      this.$nextTick(() => {
        this.$refs.SelectPrice.init("选择单价");
      });
    },
    // 更改单价
    PriceChange(val) {
      const item = this.tableList[this.currentPointIndex];
      item.price = val.price;
      this.inputChange(null, item);
      this.SelectPriceVisible = false;
    },
    clearAccountId() {
      this.pustorageForm.thisPayment = 0;
      this.handlePriceCount();
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonSeach {
  width: 20px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  cursor: pointer;
}
// 普通商品
/deep/ .GeneralGoods {
  .batchNo_label,
  .productionDate_label,
  .expire_label,
  .dateDue_label {
    background-color: #f5f7fa !important;
  }
}
/deep/ .el-table__footer-wrapper {
  .el-table__footer {
    td.el-table__cell {
      div {
        text-align: right;
        // 其他⾃定义样式
      }
    }
  }
}
</style>
