import Vue from 'vue'
import VueRouter from 'vue-router'
// import { clearLoginInfo } from '@/until/auth.js'
import store from '@/store'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {undefined
  return originalPush.call(this, location).catch(err => err)
}
// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)
// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  { path: '/Login', component: _import('common/Login'), name: 'Login', meta: { title: '登录' } },
  { path: '/register', component: _import('common/register'), name: 'register', meta: { title: '注册' } },
  { path: '/retrieve', component: _import('common/retrieve'), name: 'retrieve', meta: { title: '找回' } }
]
// 主入口路由(需嵌套上左右整体布局)
let mainRoutes = {
  path: '/',
  component: _import('layout/main'),
  redirect: { name: 'home' },
  name: 'main',
  meta: { title: '主入口整体布局' },
  children: [
    { path: '/home', component: _import('common/Home'), name: 'home', meta: { title: '首页', isTab: true } }
  ],
  beforeEnter (to, from, next) {
    let user = sessionStorage.getItem('token')
    if (!user || !/\S/.test(user)) {
      sessionStorage.clear()
      store.commit('resetStore')
      next({ name: 'Login' })
    }
    next()
  }
}

const router = new VueRouter({
  mode: 'hash',
  isAddDynamicMenuRoutes: false,
  routes: globalRoutes.concat(mainRoutes)
})
router.beforeEach((to, from, next) => {
  if (router.options.isAddDynamicMenuRoutes) {
    next()
  } else {
    if(!JSON.parse(sessionStorage.getItem('menuList'))) {
      next()
    } else {
      const allList = JSON.parse(sessionStorage.getItem('menuList')).filter(val => { return val.parent !== 0 })
      const sideMenu = allList.filter(val => { return val.url !== ''})
      const list = fnAddDynamicMenuRoutes(sideMenu)
      if(list.length !== 0 ){
        list.forEach(item => {
          mainRoutes.children.push(item)
        })
        router.addRoute(mainRoutes)
        router.options.isAddDynamicMenuRoutes = true
        next({ ...to, replace: true })
      } else {
        router.options.isAddDynamicMenuRoutes = false
        router.push({ name: 'Login' })
      }
    }
  }
})
function fnAddDynamicMenuRoutes(menuList = []) {
  let temp = []
  menuList.forEach(router => {
    let item = {
      path: '/' + router.url,
      component: null,
      name: router.url.substring(router.url.indexOf("/") + 1),
      meta: {
        id: router.id,
        title: router.name,
        isTab: true,
        parentName: router.parent
      }
    }
    try {
      item['component'] = _import(`page/${router.url}`) || null
    } catch (e) {}
    temp.push(item)
  })
  return temp
}
export default router
