<template>
    <div class="header">
        <div class="title">
            <div class="logo"></div>
            <div class="tip"></div>
            <div class="back" @click="promptlyLogin"><span>返回</span></div>
        </div>
        <div class="goLogin">已有账号 <el-button type="text" @click="promptlyLogin">去登录<i class="el-icon-arrow-right"/></el-button></div>
    </div>
</template>
<script>
export default {
    name: 'miaHeader',
    methods:{
        // 立即登录
        promptlyLogin(){
            sessionStorage.removeItem('registerItem')
            this.$router.push({name: 'Login'})
        }
    }
}
</script>
<style lang="scss" scoped>
    .header{
        width: 88%;
        height: 60px;
        padding: 0 2%;
        padding-right: 10%;
        text-align: left;
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        //#eef6ff #e0eefe
        background-color: #f5f8fd;
        border-bottom: 1px solid #E8E8E8;
        .title{
            display: inline-flex;
            flex-wrap: nowrap;
            align-items: center;
            height: 100%;
            .logo {
                width: 150px;
                height: 88%;
                background-image: url('../../assets/image/logo.png');
                background-position: top;
                background-repeat: no-repeat;
                background-size: 90%;
                margin-right: 5px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .tip{
                // display: inline-flex;
                width: 1px;
                height: 22px;
                background: #CFDFF2;
            }
            .back{
                font-size: 16px;
                // font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
                margin-left: 15px;
                cursor: pointer;
            }
        }
    }
</style>