import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '../src/assets/scss/index.scss'
import './assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import MainModule from '../src/components/index'
import JsonExcel from 'vue-json-excel'
import OpenAccount from './views/common/OpenAccount.vue'

import {
  message
} from '@/until/message';
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$message = message;
Vue.use(MainModule)
Vue.component('OpenAccount', OpenAccount)
Vue.component('downloadExcel', JsonExcel)
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
