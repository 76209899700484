export default {
    namespaced: true,
    state: {
      // 页面文档可视高度(随窗口改变大小)
      documentClientHeight: 0,
      // 导航条, 布局风格, defalut(默认) / inverse(反向)
      navbarLayoutType: 'inverse',
      // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
      sidebarLayoutSkin: 'dark',
      ActiveId: null,
      // 侧边栏, 折叠状态
      sidebarFold: false,
      // 侧边栏, 菜单
      menuList: [],
      menuActiveName: '',
      // 内容, 是否需要刷新
      contentIsNeedRefresh: false,
      // 主入口标签页
      mainTabs: [{title: '首页', urlName: 'home'}],
      mainTabsActiveName: 'home',
      // 所有菜单（侧边栏加二级菜单）
      AllmenuList: [],
      // 二级菜单
      secondMenuList:[],
      secondActiveName: ''

    },
    mutations: {
      updataActiveId(state, id){
        state.ActiveId = id
      },
      updateDocumentClientHeight (state, height) {
        state.documentClientHeight = height
      },
      updateNavbarLayoutType (state, type) {
        state.navbarLayoutType = type
      },
      updateSidebarLayoutSkin (state, skin) {
        state.sidebarLayoutSkin = skin
      },
      updateSidebarFold (state, fold) {
        state.sidebarFold = fold
      },
      updateMenuList (state, list) {
        state.menuList = list
      },
      updateMenuActiveName (state, name) {
        state.menuActiveName = name
      },
      updateContentIsNeedRefresh (state, status) {
        state.contentIsNeedRefresh = status
      },
      updateMainTabs (state, tabs) {
        state.mainTabs = tabs
      },
      updateMainTabsActiveName (state, name) {
        state.mainTabsActiveName = name
      },
      updataAllmenuList(state, list){
        state.AllmenuList = list
      },
      updataSecondMenuList(state, list){
        state.secondMenuList = list
      },
      updataSecondActiveName(state, list){
        state.secondActiveName = list
      }
    }
  }