<template>
    <div class="mia-footer">
         <p><span>备案号：<a style="color:#333" href="https://beian.miit.gov.cn/">陕ICP备16007570号-4</a></span> <span style="margin-left:20px">联系电话：400 966 7622</span></p>
        <p>Copyright© 西安普西网络科技有限公司 All Rights Reserved 版权所有</p>
    </div>
</template>
<script>
export default {
    name: 'miaFooter'
}
</script>
<style lang="scss" scoped>
.mia-footer{
    display: inline-block;
    width: 100%;
    height: 10vh;
    text-align: center;
    margin-bottom: 0%;
p{
    height: 5vh;
    line-height: 5vh;
    font-size: 14px;
    color: #666666;
  }
}
</style>