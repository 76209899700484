import request from '@/until/request.js'
const prefixName = '/gm/webWmsPus'

export function GeturchaseList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: { ...data }
  })
}
export function AddPurchase(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
export function EditPurchase(result){
  return request({
    url: prefixName,
    method: 'put',
    data: result
  })
}
// 采购单详情
export function GeturchaseInfo(id){
  return request({
    url:  prefixName + '/details',
    method: 'get',
    params: { id:id }
  })
}
// 删除采购单
export function deletePurchase(id){
  return request({
    url: prefixName + '/' + id,
    method: 'delete'
})
}
// 获取批次列表
export function GetBatchList(data){
  return request({
    url: '/gm/wms/batchList',
    method: 'get',
    params: { ...data }
  })
}
// 价格
export function GetPriceList(data){
  return request({
    url: prefixName + '/recentPrice',
    method: 'get',
    params: { ...data }
  })
}
// 获取入库单单号
export function getCode(date) {
  return request({
    url: prefixName + '/getCode',
    method: 'get',
    params: { date: date}
  })
}